interface Props {
    children: React.ReactNode | React.ReactNode[];
    isContainerFluid: boolean;
}

function ContentContainer({ children, isContainerFluid = false }: Props) {
    return (
        <div className={isContainerFluid ? "container-fluid p-0" : "container"}>
            <main role="main" className="pb-3">
                {children}
            </main>
        </div>
    );
}

export default ContentContainer;
