import { ReactComponent as LeftArrow } from "./images/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right-circle.svg";
import { ReactComponent as ArrowReset } from "./images/record-circle.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { addDays, AzureFunctionUri, dateToStr, getDateRangeString, getDayString, getMonday, parseDate, sortWorkoutsByType } from "./Constants";
import WorkoutModel, { WorkoutStatus, WorkoutType } from "./models/WorkoutModel";
import { Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import UserRoleModel, { UserRoles } from "./models/UserRoleModel";

function Schedule() {
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(getMonday(new Date()));
    const [schedule, setSchedule] = useState({} as { [key: string]: WorkoutModel[][] });

    const isMountedRefWorkout = useRef(true);
    const isMountedRefUserRole = useRef(true);

    const { isLoading, isAuthenticated, getAccessTokenSilently, user } = useAuth0();
    const history = useHistory();

    const fetchUserRole = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUserRole.current = true;

        const accessToken = await getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/UserRoles/" + encodeURIComponent(user?.sub ?? ""), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. Schedule (GET: UserRoles): ${response.statusText}(${response.status})`);
                }

                return response.json();
            })
            .then((data: UserRoleModel) => {
                if (isMountedRefUserRole.current) {
                    if (data.role === "" || data.role === UserRoles.Other) {
                        history.push("?warningMessage=Your+account+is+currently+inactive.+Please+contact+an+admin+to+activate+your+account.");
                    }
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }, [getAccessTokenSilently, history, isAuthenticated, isLoading, user?.sub]);

    const fetchWorkouts = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;

        isMountedRefWorkout.current = true;
        setLoading(true);
        var monday = getMonday(date);

        const accessToken = await getAccessTokenSilently();
        if (!user?.sub) return;

        fetch(AzureFunctionUri + "api/WeeklyWorkout/" + encodeURIComponent(user?.sub ?? "") + "/" + dateToStr(date), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Schedule (GET: WeeklyWorkout): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: WorkoutModel[]) => {
                const updatedModel = { ...schedule };
                var workoutWeek = [] as WorkoutModel[][];

                var dailyWorkouts = {} as { [key: string]: WorkoutModel[] };
                var workoutIndexes: string[] = [];
                if (data.length > 0) {
                    data.forEach((workout: WorkoutModel) => {
                        if (dailyWorkouts[workout.date]) {
                            dailyWorkouts[workout.date].push(workout);
                        } else {
                            workoutIndexes.push(workout.date);
                            dailyWorkouts[workout.date] = [workout];
                        }
                    });
                }

                workoutIndexes = workoutIndexes.sort((a: string, b: string) => {
                    return parseDate(a).valueOf() - parseDate(b).valueOf();
                });

                workoutIndexes.forEach((workoutDate: string) => {
                    var workouts = dailyWorkouts[workoutDate];
                    workouts = sortWorkoutsByType(workouts);
                    workoutWeek.push(workouts);
                });

                updatedModel[dateToStr(monday)] = workoutWeek;

                if (isMountedRefWorkout.current) {
                    setSchedule(updatedModel);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRefWorkout.current) setLoading(false);
            });
    }, [getAccessTokenSilently, isLoading, isAuthenticated, date, schedule, user]);

    useEffect(() => {
        var workout = schedule[dateToStr(date)];
        if (!workout) {
            fetchWorkouts();
        }

        return () => {
            isMountedRefWorkout.current = false;
        };
    }, [date, fetchWorkouts, schedule]);

    useEffect(() => {
        if (user?.sub) {
            fetchUserRole();
        }

        return () => {
            isMountedRefUserRole.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </symbol>
                <symbol id="x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </symbol>
            </svg>
            <h1>Schedule</h1>
            <div>
                <div className="row mb-1">
                    <div className="col d-flex align-items-center">
                        <h5 className="m-0">{getDateRangeString(date)}</h5>
                    </div>
                </div>
                <div className="d-flex justify-content-end">
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(addDays(date, -7));
                        }}
                    >
                        <LeftArrow className="m-1" width="30" height="30" />
                    </button>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(getMonday(new Date()));
                        }}
                    >
                        <ArrowReset className="m-1" width="30" height="30" />
                    </button>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(addDays(date, 7));
                        }}
                    >
                        <RightArrow className="m-1" width="30" height="30" />
                    </button>
                </div>
            </div>
            <div>
                {!loading &&
                    schedule &&
                    schedule[dateToStr(getMonday(date))] &&
                    (schedule[dateToStr(getMonday(date))].length > 0 ? (
                        schedule[dateToStr(getMonday(date))].map((workouts: WorkoutModel[], workoutsIndex: number) => {
                            if (workouts.length > 0) {
                                return (
                                    <Card key={workoutsIndex} border="purple" className="mb-2 border-3">
                                        <Card.Header>{getDayString(parseDate(workouts[0].date)) + ", " + workouts[0].date}</Card.Header>
                                        <Card.Body>
                                            {workouts.map((workout: WorkoutModel, workoutIndex: number) => {
                                                return (
                                                    <div key={`${workoutsIndex}${workoutIndex}`}>
                                                        <Card.Title>{workout.name}</Card.Title>
                                                        <Card.Text as="div">
                                                            <div>
                                                                Type: {workout.type === WorkoutType.WarmUp && "Warm Up"}
                                                                {workout.type === WorkoutType.Main && "Main"}
                                                                {workout.type === WorkoutType.Finishers && "Finishers"}
                                                                {workout.type === WorkoutType.CoolDown && "Cool Down"}
                                                            </div>
                                                            <div>
                                                                Status:
                                                                {workout.status === WorkoutStatus.Completed && (
                                                                    <svg className="bi bi-check-circle ms-2" width="16" height="16" fill="green">
                                                                        <use xlinkHref="#check-circle" />
                                                                    </svg>
                                                                )}
                                                                {workout.status === WorkoutStatus.Started && (
                                                                    <svg className="bi bi-check-circle ms-2" width="16" height="16" fill="#D4AC0D">
                                                                        <use xlinkHref="#check-circle" />
                                                                    </svg>
                                                                )}
                                                                {workout.status === WorkoutStatus.NotStarted && (
                                                                    <svg className="bi bi-x-circle align-middle ms-2" width="16" height="16" fill="red">
                                                                        <use xlinkHref="#x-circle" />
                                                                    </svg>
                                                                )}
                                                            </div>
                                                            <div className="mt-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-maroon"
                                                                    onClick={() => {
                                                                        var idStr = workout.id ? `&id=${workout.id}` : "";
                                                                        history.push(`/Workout/View?userId=${workout.user_id}${idStr}`);
                                                                    }}
                                                                >
                                                                    View
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-purple float-end"
                                                                    onClick={() => {
                                                                        var idStr = workout.id ? `&id=${workout.id}` : "";
                                                                        history.push(`/Workout/Start?userId=${workout.user_id}${idStr}`);
                                                                    }}
                                                                >
                                                                    Start
                                                                </button>
                                                            </div>
                                                        </Card.Text>
                                                        {workoutIndex < workouts.length - 1 && <hr />}
                                                    </div>
                                                );
                                            })}
                                        </Card.Body>
                                    </Card>
                                );
                            } else {
                                return <div></div>;
                            }
                        })
                    ) : (
                        <div className="d-flex justify-content-center mt-2">
                            <h3>No Records</h3>
                        </div>
                    ))}
            </div>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Schedule;
