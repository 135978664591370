import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
    addMinSetsToWorkout,
    AzureFunctionUri,
    sortSetsInWorkout,
    useQuery,
    youtube_parser,
} from "./Constants";
import WorkoutModel, {
    SetModel,
    WorkoutExerciseModel,
    WorkoutStatus,
    WorkoutType,
} from "./models/WorkoutModel";
import YoutubeLogo from "./images/yt_icon.png";
import RestTimer from "./RestTimer";

function StartWorkout() {
    const [loading, setLoading] = useState(true);
    const [userId, setUserId] = useState("");
    const [id, setId] = useState("");
    const [workout, setWorkout] = useState({} as WorkoutModel);
    const [showYoutubeModal, setShowYoutubeModal] = useState(false);
    const [youtubeModalLink, setYoutubeModalLink] = useState("");

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const query = useQuery();
    const history = useHistory();

    const fetchWorkout = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(
            AzureFunctionUri +
                "api/Workout/" +
                encodeURIComponent(userId) +
                "/" +
                id,
            {
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Network response was not ok. StartWorkout (GET: Workout): " +
                            response.statusText +
                            "(" +
                            response.status +
                            ")"
                    );
                }
                return response.json();
            })
            .then((data) => {
                var newWorkout: WorkoutModel = JSON.parse(data);
                if (isMountedRef.current) {
                    sortSetsInWorkout(newWorkout);
                    addMinSetsToWorkout(newWorkout);
                    setWorkout(newWorkout);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
                history.push("/?errorMessage=Unable+to+get+workout.");
            });
    }, [
        id,
        getAccessTokenSilently,
        history,
        isAuthenticated,
        isLoading,
        userId,
    ]);

    function handleWorkoutInputChange(event: any) {
        const target = event.target;
        const name = target.name;
        if (name === "status") {
            const value =
                target.type === "checkbox" ? target.checked : target.value;

            var newWorkout: any = { ...workout };
            if (newWorkout) {
                newWorkout[name] = value;
                if (isMountedRef.current) {
                    setWorkout(newWorkout);
                }
            }
        }
    }

    function handleRepsChange(
        event: any,
        exerciseGroupIndex: number,
        exercisesIndex: number,
        setIndex: number
    ) {
        const value = event.target.value;
        var valueInt = parseInt(value);
        var newWorkout = { ...workout };
        if (valueInt || valueInt === 0) {
            newWorkout.exercises[exerciseGroupIndex][exercisesIndex].sets[
                setIndex
            ].reps = valueInt;
        } else {
            newWorkout.exercises[exerciseGroupIndex][exercisesIndex].sets[
                setIndex
            ].reps = "";
        }
        setWorkout(newWorkout);
    }

    function handleWeightChange(
        event: any,
        exerciseGroupIndex: number,
        exercisesIndex: number,
        setIndex: number
    ) {
        const value = event.target.value;
        var valueFloat = parseFloat(value);
        var newWorkout = { ...workout };
        if (valueFloat || valueFloat === 0) {
            newWorkout.exercises[exerciseGroupIndex][exercisesIndex].sets[
                setIndex
            ].weight = valueFloat;
        } else {
            newWorkout.exercises[exerciseGroupIndex][exercisesIndex].sets[
                setIndex
            ].weight = "";
        }
        setWorkout(newWorkout);
    }

    function addSetToExercise(exerciseGroupIndex: number) {
        var newWorkout = { ...workout };
        var exerciseGroup = newWorkout.exercises[exerciseGroupIndex];
        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            exercise.sets.push({
                order: exercise.sets.length,
                reps: 0,
                weight: 0,
            });
        });
        setWorkout(newWorkout);
    }

    function deleteSetFromExercise(exerciseGroupIndex: number) {
        var newWorkout = { ...workout };
        var exerciseGroup = newWorkout.exercises[exerciseGroupIndex];
        var minSets = 0;

        if (exerciseGroup.length === 1) {
            minSets = exerciseGroup[0].min_sets;
        } else {
            exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
                if (exercise.min_sets > minSets) {
                    minSets = exercise.min_sets;
                }
            });
        }

        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            if (exercise.sets.length > minSets) {
                exercise.sets.pop();
            }
        });
        setWorkout(newWorkout);
    }

    function openYoutubeModal(videoLink: string) {
        var link = "https://www.youtube.com/embed/" + youtube_parser(videoLink);
        setYoutubeModalLink(link);
        setShowYoutubeModal(true);
    }

    async function submitWorkout() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        var newWorkout = { ...workout };
        if (workout.status === WorkoutStatus.NotStarted) {
            newWorkout.status = WorkoutStatus.Started;
            setWorkout(newWorkout);
        }

        fetch(
            AzureFunctionUri +
                "api/Workout/" +
                encodeURIComponent(newWorkout.user_id) +
                "/" +
                newWorkout.id,
            {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                body: JSON.stringify(newWorkout),
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Network response was not ok. StartWorkout (PUT: Workout): " +
                            response.statusText +
                            "(" +
                            response.status +
                            ")"
                    );
                }

                if (isMountedRef.current) {
                    setLoading(false);
                    isMountedRef.current = false;
                    history.goBack();
                    history.push("?successMessage=Successfully+saved+workout.");
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) {
                    setLoading(false);
                    history.goBack();
                    history.push(
                        "?errorMessage=Unable+to+create+or+edit+workout.&userId=" +
                            encodeURIComponent(userId)
                    );
                }
            });
    }

    useEffect(() => {
        if (!userId) {
            if (query.has("userId")) {
                setUserId(query.get("userId") ?? "");
            } else {
                history.push("/?errorMessage=An+error+has+occurred.");
            }
        }
        if (!id) {
            if (query.has("id")) {
                setId(query.get("id") ?? "");
            } else {
                history.push("/?errorMessage=An+error+has+occurred.");
            }
        }
    }, [history, query, userId, id]);

    useEffect(() => {
        if (userId && id) {
            fetchWorkout();
        }

        return () => {
            if (isMountedRef.current) {
                isMountedRef.current = false;
            }
        };
    }, [id, fetchWorkout, userId]);

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </symbol>
                <symbol id="x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </symbol>
                <symbol id="trash-can" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                </symbol>
            </svg>
            <h1>Workout!</h1>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {workout && (
                <div>
                    <div className="mb-3">
                        <label htmlFor="userIdInput" className="form-label">
                            User Id
                        </label>
                        <input
                            type="name"
                            className="form-control"
                            id="userIdInput"
                            placeholder="User Id"
                            name="user_id"
                            value={workout.user_id ?? ""}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="dateInput" className="form-label">
                            Date
                        </label>
                        <input
                            type="name"
                            className="form-control"
                            id="dateInput"
                            placeholder="Date"
                            name="date"
                            value={workout.date ?? ""}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="nameInput" className="form-label">
                            Workout Name
                        </label>
                        <input
                            type="name"
                            className="form-control"
                            id="nameInput"
                            placeholder="Name"
                            name="name"
                            value={workout.name ?? ""}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="typeInput" className="form-label">
                            Workout Type
                        </label>
                        <select
                            id="typeInput"
                            className="form-select"
                            aria-label="Select Workout Type"
                            name="type"
                            value={workout.type ?? ""}
                            disabled
                        >
                            <option value={WorkoutType.Main}>Main</option>
                            <option value={WorkoutType.WarmUp}>Warm Up</option>
                            <option value={WorkoutType.Finishers}>
                                Finishers
                            </option>
                            <option value={WorkoutType.CoolDown}>
                                Cool Down
                            </option>
                        </select>
                    </div>
                    {workout.exercises &&
                        workout.exercises.map(
                            (
                                workoutExerciseGroup: WorkoutExerciseModel[],
                                workoutExerciseGroupIndex: number
                            ) => {
                                var borderColor = "purple";
                                var cardHeader = "Normal Exercise";
                                if (workoutExerciseGroup.length > 1) {
                                    borderColor = "maroon";
                                    cardHeader = "Superset Exercise";
                                }
                                let numberOfSets =
                                    workoutExerciseGroup[0]?.sets?.length ?? 0;
                                let setsArray: number[] = [];
                                if (workoutExerciseGroup.length > 1) {
                                    for (let i = 0; i < numberOfSets; i++) {
                                        setsArray.push(i);
                                    }
                                }
                                return (
                                    <Card
                                        key={workoutExerciseGroupIndex}
                                        border={borderColor}
                                        className="mb-2 border-3"
                                    >
                                        <Card.Header>{cardHeader}</Card.Header>
                                        <Card.Body>
                                            {workoutExerciseGroup.map(
                                                (
                                                    workoutExercise: WorkoutExerciseModel,
                                                    workoutExerciseIndex: number
                                                ) => {
                                                    return (
                                                        <div
                                                            key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                        >
                                                            {(workoutExercise.id ||
                                                                workoutExercise.is_custom) && (
                                                                <>
                                                                    <div className="d-flex justify-content-between">
                                                                        <Card.Title className="d-inline">
                                                                            {
                                                                                workoutExercise.name
                                                                            }
                                                                        </Card.Title>{" "}
                                                                        {workoutExercise.video_link && (
                                                                            <div className="d-inline align-middle">
                                                                                <img
                                                                                    src={
                                                                                        YoutubeLogo
                                                                                    }
                                                                                    alt="Youtube Logo"
                                                                                    height="35"
                                                                                    onClick={() => {
                                                                                        openYoutubeModal(
                                                                                            workoutExercise.video_link
                                                                                        );
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                    <Card.Text as="div">
                                                                        <div className="mb-3">
                                                                            <label
                                                                                htmlFor={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                className="form-label"
                                                                            >
                                                                                General
                                                                                Description
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                id={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                placeholder="General Description"
                                                                                name="general_description"
                                                                                value={
                                                                                    workoutExercise.general_description ??
                                                                                    ""
                                                                                }
                                                                                rows={
                                                                                    3
                                                                                }
                                                                                readOnly
                                                                            ></textarea>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label
                                                                                htmlFor={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                className="form-label"
                                                                            >
                                                                                Custom
                                                                                Description
                                                                            </label>
                                                                            <textarea
                                                                                className="form-control"
                                                                                id={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                placeholder="Custom Description"
                                                                                name="custom_description"
                                                                                value={
                                                                                    workoutExercise.custom_description ??
                                                                                    ""
                                                                                }
                                                                                rows={
                                                                                    3
                                                                                }
                                                                                readOnly
                                                                            ></textarea>
                                                                        </div>
                                                                        <div className="mb-3">
                                                                            <label
                                                                                htmlFor={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                className="form-label"
                                                                            >
                                                                                Minimum
                                                                                Sets
                                                                            </label>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                id={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                placeholder="1"
                                                                                name="min_sets"
                                                                                min={
                                                                                    0
                                                                                }
                                                                                value={
                                                                                    workoutExercise.min_sets ??
                                                                                    ""
                                                                                }
                                                                                readOnly
                                                                            />
                                                                        </div>
                                                                        {workoutExercise.sets &&
                                                                            workoutExercise.sets.map(
                                                                                (
                                                                                    set: SetModel,
                                                                                    setIndex: number
                                                                                ) => {
                                                                                    if (
                                                                                        workoutExerciseGroup.length >
                                                                                        1
                                                                                    )
                                                                                        return (
                                                                                            <div
                                                                                                key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                            ></div>
                                                                                        );
                                                                                    return (
                                                                                        <div
                                                                                            key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                        >
                                                                                            <div className="d-flex justify-content-between">
                                                                                                <div>
                                                                                                    <b>
                                                                                                        Set{" "}
                                                                                                        {set.order +
                                                                                                            1}

                                                                                                        :
                                                                                                    </b>
                                                                                                </div>
                                                                                                <div className="text-muted">
                                                                                                    {workoutExercise.previous_sets &&
                                                                                                        workoutExercise
                                                                                                            .previous_sets[
                                                                                                            setIndex
                                                                                                        ] &&
                                                                                                        workoutExercise
                                                                                                            .previous_sets[
                                                                                                            setIndex
                                                                                                        ]
                                                                                                            .reps &&
                                                                                                        workoutExercise
                                                                                                            .previous_sets[
                                                                                                            setIndex
                                                                                                        ]
                                                                                                            .weight &&
                                                                                                        "(" +
                                                                                                            workoutExercise
                                                                                                                .previous_sets[
                                                                                                                setIndex
                                                                                                            ]
                                                                                                                .reps +
                                                                                                            " x " +
                                                                                                            workoutExercise
                                                                                                                .previous_sets[
                                                                                                                setIndex
                                                                                                            ]
                                                                                                                .weight +
                                                                                                            "kg)"}
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="d-flex align-items-center mb-3">
                                                                                                <div className="col-5">
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="form-control text-center"
                                                                                                        id={`repsFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                                        placeholder="Reps"
                                                                                                        value={
                                                                                                            set.reps ??
                                                                                                            0
                                                                                                        }
                                                                                                        min={
                                                                                                            0
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            handleRepsChange(
                                                                                                                e,
                                                                                                                workoutExerciseGroupIndex,
                                                                                                                workoutExerciseIndex,
                                                                                                                setIndex
                                                                                                            );
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            loading
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-1 text-center">
                                                                                                    X
                                                                                                </div>
                                                                                                <div className="col-5">
                                                                                                    <input
                                                                                                        type="number"
                                                                                                        className="form-control text-center"
                                                                                                        id={`weightFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                                        placeholder="Weight"
                                                                                                        value={
                                                                                                            set.weight ??
                                                                                                            0
                                                                                                        }
                                                                                                        step="any"
                                                                                                        min={
                                                                                                            0
                                                                                                        }
                                                                                                        onChange={(
                                                                                                            e
                                                                                                        ) => {
                                                                                                            handleWeightChange(
                                                                                                                e,
                                                                                                                workoutExerciseGroupIndex,
                                                                                                                workoutExerciseIndex,
                                                                                                                setIndex
                                                                                                            );
                                                                                                        }}
                                                                                                        disabled={
                                                                                                            loading
                                                                                                        }
                                                                                                    />
                                                                                                </div>
                                                                                                <div className="col-1 text-center">
                                                                                                    kg
                                                                                                </div>
                                                                                            </div>
                                                                                            <RestTimer className="mb-1" />
                                                                                        </div>
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </Card.Text>
                                                                </>
                                                            )}
                                                            {workoutExerciseGroup.length >
                                                                1 &&
                                                                workoutExerciseIndex <
                                                                    workoutExerciseGroup.length && (
                                                                    <hr />
                                                                )}
                                                        </div>
                                                    );
                                                }
                                            )}
                                            {setsArray.length > 0 && (
                                                <Card.Text as="div">
                                                    {setsArray.map(
                                                        (setIndex: number) => {
                                                            return (
                                                                <div
                                                                    key={`${workoutExerciseGroupIndex}${0}${setIndex}`}
                                                                >
                                                                    {workoutExerciseGroup.map(
                                                                        (
                                                                            workoutExercise: WorkoutExerciseModel,
                                                                            workoutExerciseIndex: number
                                                                        ) => {
                                                                            const set =
                                                                                workoutExerciseGroup[
                                                                                    workoutExerciseIndex
                                                                                ]
                                                                                    .sets[
                                                                                    setIndex
                                                                                ];
                                                                            return (
                                                                                <div
                                                                                    key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                >
                                                                                    <Card.Title>
                                                                                        {
                                                                                            workoutExercise.name
                                                                                        }
                                                                                    </Card.Title>
                                                                                    <div>
                                                                                        <div className="d-flex justify-content-between">
                                                                                            <div>
                                                                                                <b>
                                                                                                    Set{" "}
                                                                                                    {set.order +
                                                                                                        1}

                                                                                                    :
                                                                                                </b>
                                                                                            </div>
                                                                                            <div className="text-muted">
                                                                                                {workoutExercise.previous_sets &&
                                                                                                    workoutExercise
                                                                                                        .previous_sets[
                                                                                                        setIndex
                                                                                                    ] &&
                                                                                                    workoutExercise
                                                                                                        .previous_sets[
                                                                                                        setIndex
                                                                                                    ]
                                                                                                        .reps !==
                                                                                                        undefined &&
                                                                                                    workoutExercise
                                                                                                        .previous_sets[
                                                                                                        setIndex
                                                                                                    ]
                                                                                                        .weight !==
                                                                                                        undefined && (
                                                                                                        <>
                                                                                                            {`(${workoutExercise.previous_sets[setIndex].reps} x ${workoutExercise.previous_sets[setIndex].weight}kg)`}
                                                                                                        </>
                                                                                                    )}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="d-flex align-items-center mb-3">
                                                                                            <div className="col-5">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control text-center"
                                                                                                    id={`repsFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                                    placeholder="Reps"
                                                                                                    value={
                                                                                                        set.reps ??
                                                                                                        0
                                                                                                    }
                                                                                                    min={
                                                                                                        0
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        handleRepsChange(
                                                                                                            e,
                                                                                                            workoutExerciseGroupIndex,
                                                                                                            workoutExerciseIndex,
                                                                                                            setIndex
                                                                                                        );
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        loading
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-1 text-center">
                                                                                                X
                                                                                            </div>
                                                                                            <div className="col-5">
                                                                                                <input
                                                                                                    type="number"
                                                                                                    className="form-control text-center"
                                                                                                    id={`weightFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                                    placeholder="Weight"
                                                                                                    value={
                                                                                                        set.weight ??
                                                                                                        0
                                                                                                    }
                                                                                                    step="any"
                                                                                                    min={
                                                                                                        0
                                                                                                    }
                                                                                                    onChange={(
                                                                                                        e
                                                                                                    ) => {
                                                                                                        handleWeightChange(
                                                                                                            e,
                                                                                                            workoutExerciseGroupIndex,
                                                                                                            workoutExerciseIndex,
                                                                                                            setIndex
                                                                                                        );
                                                                                                    }}
                                                                                                    disabled={
                                                                                                        loading
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-1 text-center">
                                                                                                kg
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            );
                                                                        }
                                                                    )}
                                                                    <RestTimer />
                                                                    {setIndex <
                                                                        setsArray.length -
                                                                            1 && (
                                                                        <hr />
                                                                    )}
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </Card.Text>
                                            )}
                                        </Card.Body>
                                        <Card.Footer>
                                            <button
                                                className="btn btn-outline-danger float-end"
                                                type="button"
                                                onClick={() => {
                                                    deleteSetFromExercise(
                                                        workoutExerciseGroupIndex
                                                    );
                                                }}
                                            >
                                                <svg
                                                    className="bi bi-trash"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                >
                                                    <use xlinkHref="#trash-can" />
                                                </svg>
                                            </button>
                                            <button
                                                className="btn btn-maroon float-end me-1"
                                                type="button"
                                                onClick={() => {
                                                    addSetToExercise(
                                                        workoutExerciseGroupIndex
                                                    );
                                                }}
                                            >
                                                Add Set
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                );
                            }
                        )}
                    <div className="mb-3">
                        <label htmlFor="statusSelect" className="form-label">
                            Workout Status
                        </label>
                        {workout.status === WorkoutStatus.NotStarted && (
                            <svg
                                className="bi bi-x-circle align-middle ms-2"
                                width="16"
                                height="16"
                                fill="red"
                            >
                                <use xlinkHref="#x-circle" />
                            </svg>
                        )}
                        {workout.status === WorkoutStatus.Started && (
                            <svg
                                className="bi bi-check-circle ms-2"
                                width="16"
                                height="16"
                                fill="#D4AC0D"
                            >
                                <use xlinkHref="#check-circle" />
                            </svg>
                        )}
                        {workout.status === WorkoutStatus.Completed && (
                            <svg
                                className="bi bi-check-circle ms-2"
                                width="16"
                                height="16"
                                fill="green"
                            >
                                <use xlinkHref="#check-circle" />
                            </svg>
                        )}
                        <select
                            className="form-select"
                            aria-label="Workout Status Select"
                            name="status"
                            id="statusSelect"
                            value={workout.status}
                            onChange={handleWorkoutInputChange}
                            disabled={loading}
                        >
                            <option value={WorkoutStatus.NotStarted}>
                                Not Started
                            </option>
                            <option value={WorkoutStatus.Started}>
                                Started
                            </option>
                            <option value={WorkoutStatus.Completed}>
                                Completed
                            </option>
                        </select>
                    </div>
                </div>
            )}
            <button
                type="button"
                className="btn btn-maroon mt-2"
                onClick={() => {
                    history.goBack();
                }}
            >
                Back
            </button>
            <button
                type="button"
                className="btn btn-purple mt-2 float-end"
                onClick={() => {
                    submitWorkout();
                }}
            >
                Save
            </button>
            <Modal
                size="xl"
                show={showYoutubeModal}
                centered={true}
                onHide={() => {
                    setShowYoutubeModal(false);
                    setYoutubeModalLink("");
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Youtube Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: 0,
                            paddingBottom: "56.25%",
                        }}
                    >
                        {youtubeModalLink && (
                            <iframe
                                className="mt-1"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                                src={youtubeModalLink}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="100%"
                            ></iframe>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default StartWorkout;
