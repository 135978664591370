interface UserRoleModel {
    user_id: string;
    role: string;
}

enum UserRoles {
    /// <summary>
    /// Users that have been assigned no role.
    /// </summary>
    Other = "Other",

    /// <summary>
    /// Users that have been assigned user role.
    /// </summary>
    User = "User",

    /// <summary>
    /// Users that have been assigned admin role.
    /// </summary>
    Admin = "Admin",
}

export default UserRoleModel;
export { UserRoles };
