import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, moveExerciseDown, moveExerciseGroupDown, moveExerciseGroupUp, moveExerciseUp, useQuery } from "./Constants";
import { Auth0User } from "./models/Auth0Users";
import ExerciseModel, { ExerciseType } from "./models/ExerciseModel";
import WorkoutModel, { SetModel, WorkoutExerciseModel, WorkoutStatus, WorkoutType } from "./models/WorkoutModel";
import { ReactComponent as UpArrow } from "./images/arrow-up-circle.svg";
import { ReactComponent as DownArrow } from "./images/arrow-down-circle.svg";
import Select from "react-select";

interface ExerciseOptions {
    label: string;
    options: ExerciseOption[];
}

interface ExerciseOption {
    value: string;
    label: string;
    exercise: ExerciseModel;
}

function CreateWorkout() {
    const [loadingUser, setLoadingUser] = useState(true);
    const [userId, setUserId] = useState("");
    const [user, setUser] = useState({} as Auth0User);
    const isMountedRefUser = useRef(true);

    const [id, setId] = useState("");

    const [loadingWorkout, setLoadingWorkout] = useState(true);
    const [workout, setWorkout] = useState({
        user_id: "",
        id: "",
        date: "",
        name: "",
        status: WorkoutStatus.NotStarted,
        workout_file_name: "",
        type: WorkoutType.Main,
        exercises: [] as WorkoutExerciseModel[][],
    } as WorkoutModel);
    const isMountedRefWorkout = useRef(true);
    const workoutRef = useRef(workout);

    const [loadingExercises, setLoadingExercises] = useState(true);
    const [exerciseGroupList, setExerciseGroupList] = useState([] as ExerciseOptions[]);
    const isMountedRefExercises = useRef(true);

    const [showRearrangeModal, setShowRearrangeModal] = useState(false);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const query = useQuery();
    const history = useHistory();

    const fetchUser = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUser.current = true;
        setLoadingUser(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/User/" + encodeURIComponent(userId), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. CreateWorkout (GET: User): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: Auth0User) => {
                if (isMountedRefUser.current) {
                    if (!data.user_id) {
                        throw new Error("Unable to get user.");
                    }
                    setUser(data);
                    if (!workoutRef.current.user_id) {
                        var newWorkout = { ...workoutRef.current };
                        newWorkout.user_id = data.user_id;
                        setWorkout(newWorkout);
                        workoutRef.current = newWorkout;
                    }
                    setLoadingUser(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                history.push("/Users?errorMessage=An+error+has+occurred.");
            });
    }, [getAccessTokenSilently, isLoading, isAuthenticated, userId, history]);

    const fetchExercises = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefExercises.current = true;
        setLoadingExercises(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Exercises", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. CreateWorkout (GET: Exercises): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: ExerciseModel[]) => {
                if (isMountedRefExercises.current) {
                    setLoadingExercises(false);
                    setExerciseGroupList(createExerciseGroups(data));
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRefExercises.current) setLoadingExercises(false);
            });
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    const fetchWorkout = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefWorkout.current = true;
        setLoadingWorkout(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Workout/" + encodeURIComponent(userId) + "/" + id, {
            method: "GET",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. CreateWorkout (GET: Workout): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data) => {
                if (isMountedRefWorkout.current) {
                    var newModel = JSON.parse(data);
                    var split = newModel.date.split("-");
                    newModel.date = split[2] + "-" + split[1] + "-" + split[0];
                    setWorkout(newModel);
                    workoutRef.current = newModel;
                    setLoadingWorkout(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRefWorkout.current) setLoadingWorkout(false);
                history.push("/User?errorMessage=Unable+to+get+workout.&userId=" + encodeURIComponent(userId));
            });
    }, [id, getAccessTokenSilently, isLoading, isAuthenticated, userId, history]);

    const submitWorkout = useCallback(
        async (newWorkout: WorkoutModel) => {
            if (isLoading || (!isLoading && !isAuthenticated)) return;
            isMountedRefWorkout.current = true;
            setLoadingWorkout(true);

            const accessToken = await getAccessTokenSilently();

            fetch(AzureFunctionUri + "api/Workout/" + encodeURIComponent(userId) + "/" + newWorkout.id, {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                body: JSON.stringify(newWorkout),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok. CreateWorkout (PUT: Workout): " + response.statusText + "(" + response.status + ")");
                    }
                    isMountedRefWorkout.current = false;
                    history.push("/User?successMessage=Successfully+created+workout.&userId=" + encodeURIComponent(userId));
                })
                .catch((error) => {
                    console.error(error);
                    if (isMountedRefWorkout.current) setLoadingWorkout(false);
                    history.push("/User?errorMessage=Unable+to+create+or+edit+workout.&userId=" + encodeURIComponent(userId));
                });
        },
        [getAccessTokenSilently, isAuthenticated, isLoading, userId, history]
    );

    function filterOption({ label, value }: { label: string; value: string }, string: string) {
        // default search
        if (label.toLocaleLowerCase().includes(string.toLocaleLowerCase())) {
            return true;
        }

        // check if a group as the filter string as label
        let groupOptions = exerciseGroupList.filter((group) => {
            if (group.label.toLocaleLowerCase().includes(string.toLocaleLowerCase())) {
                return group.options.filter((exercise) => {
                    return exercise.value === value;
                }).length;
            }
            return false;
        });

        if (groupOptions.length > 0) {
            return true;
        }

        return false;
    }

    function createExerciseGroups(exercises: ExerciseModel[]) {
        var exerciseGroups = {} as { [key: string]: ExerciseOption[] };

        exercises.forEach((exercise) => {
            if (!exercise.type) {
                exercise.type = ExerciseType.Other;
            }
            if (exerciseGroups[exercise.type]) {
                exerciseGroups[exercise.type].push({
                    label: exercise.name,
                    value: exercise.id,
                    exercise: exercise,
                });
            } else {
                exerciseGroups[exercise.type] = [
                    {
                        label: exercise.name,
                        value: exercise.id,
                        exercise: exercise,
                    },
                ];
            }
        });

        var exerciseGroupOptions = [] as ExerciseOptions[];

        Object.keys(exerciseGroups).forEach((exerciseGroupKey) => {
            exerciseGroupOptions.push({
                label: exerciseGroupKey,
                options: exerciseGroups[exerciseGroupKey],
            } as ExerciseOptions);
        });

        return exerciseGroupOptions;
    }

    function addExerciseGroup() {
        var newWorkout = { ...workout };
        if (!newWorkout.exercises) {
            newWorkout.exercises = [] as WorkoutExerciseModel[][];
        }
        newWorkout.exercises.push([
            {
                id: "",
                key: "",
                name: "",
                general_description: "",
                video_link: "",
                custom_description: "",
                min_sets: 1,
                sets: [] as SetModel[],
                previous_sets: [] as SetModel[],
                is_custom: false,
            } as WorkoutExerciseModel,
        ] as WorkoutExerciseModel[]);
        setWorkout(newWorkout);
    }

    function deleteExerciseGroup(workoutExerciseGroupIndex: number) {
        var newWorkout = { ...workout };
        newWorkout.exercises.splice(workoutExerciseGroupIndex, 1);
        setWorkout(newWorkout);
    }

    function addExerciseToGroup(workoutExerciseGroupIndex: number) {
        var newWorkout = { ...workout };
        newWorkout.exercises[workoutExerciseGroupIndex].push({
            id: "",
            key: "",
            name: "",
            general_description: "",
            video_link: "",
            custom_description: "",
            min_sets: 1,
            sets: [] as SetModel[],
            previous_sets: [] as SetModel[],
            is_custom: false,
        } as WorkoutExerciseModel);
        setWorkout(newWorkout);
    }

    function deleteExerciseFromGroup(workoutExerciseGroupIndex: number, workoutExerciseIndex: number) {
        var newWorkout = { ...workout };
        newWorkout.exercises[workoutExerciseGroupIndex].splice(workoutExerciseIndex, 1);
        setWorkout(newWorkout);
        if (newWorkout.exercises[workoutExerciseGroupIndex].length === 0) {
            addExerciseToGroup(workoutExerciseGroupIndex);
        }
    }

    function selectExercise(exercise: ExerciseModel | undefined, workoutExerciseGroupIndex: number, workoutExerciseIndex: number = 0) {
        if (exercise) {
            var newWorkout = { ...workout };
            var newExercise: WorkoutExerciseModel = {
                ...exercise,
                is_custom: false,
                custom_description: "",
                min_sets: 1,
                sets: [] as SetModel[],
                previous_sets: [] as SetModel[],
            };
            newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex] = newExercise;
            setWorkout(newWorkout);
        }
    }

    function handleWorkoutInputChange(event: any) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        var newWorkout: any = { ...workout };
        if (newWorkout) {
            newWorkout[name] = value;
            setWorkout(newWorkout);
        }
    }

    function handleWorkoutExerciseInputChange(event: any, workoutExerciseGroupIndex: number, workoutExerciseIndex: number) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        var newWorkout: any = { ...workout };
        if (newWorkout) {
            newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex][name] = value;
            setWorkout(newWorkout);
        }
    }

    function submitForm(event: any) {
        event.preventDefault();
        var newWorkout = { ...workout };

        var split = newWorkout.date.split("-");
        var newDate = split[2] + "-" + split[1] + "-" + split[0];
        newWorkout.date = newDate;

        // Delete Empty Exercises
        for (let exerciseGroupIndex = 0; exerciseGroupIndex < newWorkout.exercises.length; exerciseGroupIndex++) {
            newWorkout.exercises[exerciseGroupIndex] = newWorkout.exercises[exerciseGroupIndex].filter((workoutExercise: WorkoutExerciseModel) => {
                return workoutExercise.id || workoutExercise.is_custom ? true : false;
            });

            newWorkout.exercises[exerciseGroupIndex].forEach((workoutExercise: WorkoutExerciseModel) => {
                if (!workoutExercise.min_sets) {
                    workoutExercise.min_sets = 1;
                }
            });
        }

        // Delete Empty Exercise Groups
        newWorkout.exercises = newWorkout.exercises.filter((exerciseGroup: WorkoutExerciseModel[]) => {
            return exerciseGroup.length !== 0;
        });

        submitWorkout(newWorkout);
    }

    useEffect(() => {
        if (!userId) {
            if (query.has("userId")) {
                setUserId(query.get("userId") ?? "");
            } else {
                history.push("/Users?errorMessage=An+error+has+occurred.");
            }
        }
        if (!id) {
            if (query.has("id")) {
                setId(query.get("id") ?? "");
            } else {
                setLoadingWorkout(false);
            }
        }
    }, [history, query, userId, id]);

    useEffect(() => {
        if (id && userId) {
            fetchWorkout();
        }

        return () => {
            isMountedRefWorkout.current = false;
        };
    }, [id, userId, fetchWorkout]);

    useEffect(() => {
        if (userId && user && !user.name) {
            fetchUser();
        }

        return () => {
            isMountedRefUser.current = false;
        };
    }, [userId, user, fetchUser]);

    useEffect(() => {
        fetchExercises();

        return () => {
            isMountedRefExercises.current = false;
        };
    }, [fetchExercises]);

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="trash-can" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                </symbol>
            </svg>
            <h1>Create/Edit Workout</h1>
            <h3>{user && user.name}</h3>
            <form onSubmit={submitForm}>
                <div className="mb-3">
                    <label htmlFor="userIdInput" className="form-label">
                        User Id
                    </label>
                    <input type="name" className="form-control" id="userIdInput" placeholder="User Id" name="user_id" value={workout.user_id ?? ""} readOnly />
                </div>
                <div className="mb-3">
                    <label htmlFor="dateInput" className="form-label">
                        Date
                    </label>
                    <input
                        type="date"
                        className="form-control"
                        id="dateInput"
                        placeholder="Date"
                        name="date"
                        value={workout.date ?? ""}
                        onChange={handleWorkoutInputChange}
                        disabled={loadingWorkout}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="nameInput" className="form-label">
                        Workout Name
                    </label>
                    <input
                        type="name"
                        className="form-control"
                        id="nameInput"
                        placeholder="Name"
                        name="name"
                        value={workout.name ?? ""}
                        onChange={handleWorkoutInputChange}
                        disabled={loadingWorkout}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="typeInput" className="form-label">
                        Workout Type
                    </label>
                    <select
                        id="typeInput"
                        className="form-select"
                        aria-label="Select Workout Type"
                        name="type"
                        value={workout.type}
                        onChange={handleWorkoutInputChange}
                        disabled={loadingWorkout}
                    >
                        <option value={WorkoutType.Main}>Main</option>
                        <option value={WorkoutType.WarmUp}>Warm Up</option>
                        <option value={WorkoutType.Finishers}>Finishers</option>
                        <option value={WorkoutType.CoolDown}>Cool Down</option>
                    </select>
                </div>
                <div>
                    {workout.exercises &&
                        workout.exercises.map((workoutExerciseGroup: WorkoutExerciseModel[], workoutExerciseGroupIndex: number) => {
                            if (workoutExerciseGroup) {
                                var borderColor = "purple";
                                var cardHeader = "Normal Exercise";
                                if (workoutExerciseGroup.length > 1) {
                                    borderColor = "maroon";
                                    cardHeader = "Superset Exercise";
                                }
                                return (
                                    <Card key={workoutExerciseGroupIndex} border={borderColor} className="mb-2 border-3">
                                        <Card.Header>{cardHeader}</Card.Header>
                                        <Card.Body>
                                            {workoutExerciseGroup.map((workoutExercise: WorkoutExerciseModel, workoutExerciseIndex: number) => {
                                                return (
                                                    <div key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}`}>
                                                        {workoutExercise.id && (
                                                            <>
                                                                <Card.Title>
                                                                    <div className="d-inline">{workoutExercise.name}</div>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-outline-danger d-inline float-end"
                                                                        onClick={() => {
                                                                            deleteExerciseFromGroup(workoutExerciseGroupIndex, workoutExerciseIndex);
                                                                        }}
                                                                        disabled={loadingWorkout}
                                                                    >
                                                                        <svg className="bi bi-trash" width="16" height="16" fill="currentColor">
                                                                            <use xlinkHref="#trash-can" />
                                                                        </svg>
                                                                    </button>
                                                                </Card.Title>
                                                                <Card.Text as="div">
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            className="form-label"
                                                                        >
                                                                            General Description
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            placeholder="General Description"
                                                                            name="general_description"
                                                                            value={workoutExercise.general_description ?? ""}
                                                                            rows={3}
                                                                            readOnly
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            className="form-label"
                                                                        >
                                                                            Custom Description
                                                                        </label>
                                                                        <textarea
                                                                            className="form-control"
                                                                            id={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            placeholder="Custom Description"
                                                                            name="custom_description"
                                                                            value={workoutExercise.custom_description ?? ""}
                                                                            rows={3}
                                                                            onChange={(e) => {
                                                                                handleWorkoutExerciseInputChange(
                                                                                    e,
                                                                                    workoutExerciseGroupIndex,
                                                                                    workoutExerciseIndex
                                                                                );
                                                                            }}
                                                                            disabled={loadingWorkout}
                                                                        ></textarea>
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label
                                                                            htmlFor={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            className="form-label"
                                                                        >
                                                                            Minimum Sets
                                                                        </label>
                                                                        <input
                                                                            type="number"
                                                                            className="form-control"
                                                                            id={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                            placeholder="1"
                                                                            name="min_sets"
                                                                            min={0}
                                                                            value={workoutExercise.min_sets ?? ""}
                                                                            onChange={(e) => {
                                                                                handleWorkoutExerciseInputChange(
                                                                                    e,
                                                                                    workoutExerciseGroupIndex,
                                                                                    workoutExerciseIndex
                                                                                );
                                                                            }}
                                                                            disabled={loadingWorkout}
                                                                        />
                                                                    </div>
                                                                </Card.Text>
                                                            </>
                                                        )}
                                                        {!workoutExercise.id &&
                                                            (loadingExercises ? (
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="spinner-border spinner-border-sm" role="status">
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <>
                                                                    <div className="d-flex justify-content-between">
                                                                        <div className="mb-2">
                                                                            <div className="form-check">
                                                                                <input
                                                                                    className="form-check-input"
                                                                                    type="checkbox"
                                                                                    checked={workoutExercise.is_custom}
                                                                                    id={`customExerciseCheck${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    name="is_custom"
                                                                                    onChange={(e) => {
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        );
                                                                                    }}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label"
                                                                                    htmlFor={`customExerciseCheck${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                >
                                                                                    Create Custom Exercise
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        {workoutExercise.is_custom && (
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-outline-danger d-inline"
                                                                                onClick={() => {
                                                                                    deleteExerciseFromGroup(workoutExerciseGroupIndex, workoutExerciseIndex);
                                                                                }}
                                                                                disabled={loadingWorkout}
                                                                            >
                                                                                <svg className="bi bi-trash" width="16" height="16" fill="currentColor">
                                                                                    <use xlinkHref="#trash-can" />
                                                                                </svg>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                    {!workoutExercise.is_custom && (
                                                                        <>
                                                                            <Select
                                                                                defaultValue={{ label: "Select..." } as ExerciseOption}
                                                                                filterOption={filterOption}
                                                                                options={exerciseGroupList}
                                                                                onChange={(event) =>
                                                                                    selectExercise(
                                                                                        event?.exercise,
                                                                                        workoutExerciseGroupIndex,
                                                                                        workoutExerciseIndex
                                                                                    )
                                                                                }
                                                                            />
                                                                        </>
                                                                    )}
                                                                    {workoutExercise.is_custom && (
                                                                        <>
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor={`nameInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    className="form-label"
                                                                                >
                                                                                    Name
                                                                                </label>
                                                                                <input
                                                                                    type="name"
                                                                                    className="form-control"
                                                                                    id={`nameInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    placeholder="Name"
                                                                                    name="name"
                                                                                    value={workoutExercise.name ?? ""}
                                                                                    onChange={(e) =>
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    className="form-label"
                                                                                >
                                                                                    General Description
                                                                                </label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    placeholder="General Description"
                                                                                    name="general_description"
                                                                                    value={workoutExercise.general_description ?? ""}
                                                                                    onChange={(e) =>
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        )
                                                                                    }
                                                                                    rows={3}
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor={`videoLinkInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    className="form-label"
                                                                                >
                                                                                    Video Link
                                                                                </label>
                                                                                <input
                                                                                    type="name"
                                                                                    className="form-control"
                                                                                    id={`videoLinkInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    placeholder="Video Link"
                                                                                    name="video_link"
                                                                                    value={workoutExercise.video_link ?? ""}
                                                                                    onChange={(e) =>
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    className="form-label"
                                                                                >
                                                                                    Custom Description
                                                                                </label>
                                                                                <textarea
                                                                                    className="form-control"
                                                                                    id={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    placeholder="Custom Description"
                                                                                    name="custom_description"
                                                                                    value={workoutExercise.custom_description ?? ""}
                                                                                    rows={3}
                                                                                    onChange={(e) => {
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        );
                                                                                    }}
                                                                                    disabled={loadingWorkout}
                                                                                ></textarea>
                                                                            </div>
                                                                            <div className="mb-3">
                                                                                <label
                                                                                    htmlFor={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    className="form-label"
                                                                                >
                                                                                    Minimum Sets
                                                                                </label>
                                                                                <input
                                                                                    type="number"
                                                                                    className="form-control"
                                                                                    id={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                                    placeholder="1"
                                                                                    name="min_sets"
                                                                                    min={0}
                                                                                    value={workoutExercise.min_sets ?? ""}
                                                                                    onChange={(e) => {
                                                                                        handleWorkoutExerciseInputChange(
                                                                                            e,
                                                                                            workoutExerciseGroupIndex,
                                                                                            workoutExerciseIndex
                                                                                        );
                                                                                    }}
                                                                                    disabled={loadingWorkout}
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    )}
                                                                </>
                                                            ))}
                                                        {workoutExerciseIndex < workoutExerciseGroup.length - 1 && <hr />}
                                                    </div>
                                                );
                                            })}
                                        </Card.Body>
                                        <Card.Footer>
                                            <button
                                                className="btn btn-maroon"
                                                type="button"
                                                onClick={() => {
                                                    addExerciseToGroup(workoutExerciseGroupIndex);
                                                }}
                                                disabled={loadingWorkout}
                                            >
                                                Add To Group
                                            </button>
                                            <button
                                                className="btn btn-outline-danger float-end"
                                                type="button"
                                                onClick={() => {
                                                    deleteExerciseGroup(workoutExerciseGroupIndex);
                                                }}
                                                disabled={loadingWorkout}
                                            >
                                                <svg className="bi bi-trash" width="16" height="16" fill="currentColor">
                                                    <use xlinkHref="#trash-can" />
                                                </svg>
                                            </button>
                                        </Card.Footer>
                                    </Card>
                                );
                            }
                            return <div key={workoutExerciseGroupIndex}></div>;
                        })}
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <button
                        className="btn btn-maroon"
                        type="button"
                        onClick={() => {
                            setShowRearrangeModal(true);
                        }}
                    >
                        Rearrange
                    </button>
                    <button
                        className="btn btn-maroon"
                        type="button"
                        onClick={() => {
                            addExerciseGroup();
                        }}
                        disabled={loadingWorkout}
                    >
                        Add Exercise Group
                    </button>
                </div>
                <div>
                    <button
                        type="submit"
                        className="btn btn-purple mt-2 float-end"
                        disabled={loadingUser || loadingWorkout || !workout.name || !workout.user_id || !workout.date}
                    >
                        {(loadingUser || loadingWorkout) && (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                        {!loadingUser && !loadingWorkout && <>Save</>}
                    </button>
                    <button
                        type="button"
                        className="btn btn-maroon mt-2"
                        disabled={!userId}
                        onClick={() => {
                            history.push("/User?userId=" + encodeURIComponent(userId));
                        }}
                    >
                        Back
                    </button>
                </div>
            </form>
            <Modal show={showRearrangeModal} fullscreen="md-down" onHide={() => setShowRearrangeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Rearrange Exercises</Modal.Title>
                </Modal.Header>
                <Modal.Body className="px-0">
                    {workout &&
                        workout.exercises &&
                        workout.exercises.map((workoutExerciseGroup: WorkoutExerciseModel[], workoutExerciseGroupIndex: number) => {
                            var cardHeader = "Normal Exercise";
                            if (workoutExerciseGroup.length > 1) {
                                cardHeader = "Superset Exercise";
                            }
                            return (
                                <table className="table" key={workoutExerciseGroupIndex}>
                                    <thead>
                                        <tr>
                                            <th scope="col" className="align-middle">
                                                {cardHeader}
                                            </th>
                                            <th className="d-flex justify-content-end">
                                                <div className="btn-group" role="group" aria-label="Rearrange Exercise Group">
                                                    <button
                                                        type="button"
                                                        className="btn btn-purple"
                                                        disabled={workoutExerciseGroupIndex <= 0}
                                                        onClick={() => {
                                                            setWorkout(moveExerciseGroupUp(workout, workoutExerciseGroupIndex));
                                                        }}
                                                    >
                                                        <UpArrow width={25} height={25} />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-purple"
                                                        disabled={workoutExerciseGroupIndex >= workout.exercises.length - 1}
                                                        onClick={() => {
                                                            setWorkout(moveExerciseGroupDown(workout, workoutExerciseGroupIndex));
                                                        }}
                                                    >
                                                        <DownArrow width={25} height={25} />
                                                    </button>
                                                </div>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {workoutExerciseGroup.map((workoutExercise: WorkoutExerciseModel, workoutExerciseIndex: number) => {
                                            return (
                                                <tr key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}`}>
                                                    <td className="align-middle">{workoutExercise.name}</td>
                                                    <td className="d-flex justify-content-end">
                                                        <div className="btn-group" role="group" aria-label="Rearrange Exercise">
                                                            <button
                                                                type="button"
                                                                className="btn btn-maroon"
                                                                disabled={workoutExerciseIndex <= 0}
                                                                onClick={() => {
                                                                    setWorkout(moveExerciseUp(workout, workoutExerciseGroupIndex, workoutExerciseIndex));
                                                                }}
                                                            >
                                                                <UpArrow width={25} height={25} />
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="btn btn-maroon"
                                                                disabled={workoutExerciseIndex >= workoutExerciseGroup.length - 1}
                                                                onClick={() => {
                                                                    setWorkout(moveExerciseDown(workout, workoutExerciseGroupIndex, workoutExerciseIndex));
                                                                }}
                                                            >
                                                                <DownArrow width={25} height={25} />
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </table>
                            );
                        })}
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default CreateWorkout;
