import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { ReactComponent as LeftArrow } from "./images/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right-circle.svg";
import { AzureFunctionUri, checkIfAdmin } from "./Constants";
import Auth0Users from "./models/Auth0Users";
import { Link } from "react-router-dom";

function Users() {
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [maxPage, setMaxPage] = useState(1);
    const [usersList, setUsersList] = useState({} as Auth0Users);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);

    const fetchUsers = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfAdmin(getAccessTokenSilently))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        var pageQuery = "";
        if (page) pageQuery = "?page=" + page;
        fetch(AzureFunctionUri + "api/Users" + pageQuery, {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Users (GET: Users): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: Auth0Users) => {
                if (isMountedRef.current) {
                    setMaxPage(Math.ceil(data.total / data.limit));
                    setPage(data.start / data.limit);
                    setUsersList(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading, page]);

    useEffect(() => {
        fetchUsers();

        return () => {
            isMountedRef.current = false;
        };
    }, [fetchUsers]);

    return (
        <div>
            <h1>Users</h1>
            {!loading && (
                <div className="row mb-1">
                    <div className="col-8 d-flex align-items-center">
                        <h5 className="m-0">
                            Page {page + 1} of {maxPage}
                        </h5>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                        <button
                            className="btn p-0"
                            onClick={() => {
                                if (page > 0) setPage(page - 1);
                            }}
                            disabled={page === 0}
                        >
                            <LeftArrow className="m-1" width="30" height="30" />
                        </button>
                        <button
                            className="btn p-0"
                            onClick={() => {
                                if (page + 1 < maxPage) setPage(page + 1);
                            }}
                            disabled={page + 1 === maxPage}
                        >
                            <RightArrow className="m-1" width="30" height="30" />
                        </button>
                    </div>
                </div>
            )}
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" className="col-2">
                                #
                            </th>
                            <th scope="col" className="col-7">
                                Name
                            </th>
                            <th scope="col" className="col-3"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {!loading &&
                            usersList &&
                            usersList.users &&
                            usersList.users.map((user, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th scope="row" style={{ wordBreak: "break-all" }}>
                                            {index + 1}
                                        </th>
                                        <td style={{ wordBreak: "break-all" }}>{user.name}</td>
                                        <td style={{ wordBreak: "break-all" }}>
                                            <Link to={"/User?userId=" + encodeURIComponent(user.user_id)} className="btn btn-purple float-end">
                                                View
                                            </Link>
                                        </td>
                                    </tr>
                                );
                            })}
                    </tbody>
                </table>
                {loading && (
                    <div className="d-flex justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Users;
