import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfAdmin, youtube_parser } from "./Constants";
import ExerciseModel, { ExerciseType } from "./models/ExerciseModel";

function Exercises() {
    const [loading, setLoading] = useState(true);
    const [exerciseList, setExerciseList] = useState({} as ExerciseModel[]);
    const [viewingExercise, setViewingExercise] = useState({} as ExerciseModel);
    const [showExerciseModal, setShowExerciseModal] = useState(false);
    const [showDeleteExerciseModal, setShowDeleteExerciseModal] = useState(false);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const history = useHistory();

    const fetchExercises = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfAdmin(getAccessTokenSilently))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently({ ignoreCache: true });

        fetch(AzureFunctionUri + "api/Exercises", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Exercises (GET: Exercises): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: ExerciseModel[]) => {
                if (isMountedRef.current) {
                    setExerciseList(data);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [isLoading, isAuthenticated, getAccessTokenSilently]);

    async function deleteExercise() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfAdmin(getAccessTokenSilently))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Exercise/" + viewingExercise.key + "/" + viewingExercise.id, {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Exercises (DELETE: Exercise): " + response.statusText + "(" + response.status + ")");
                }
                fetchExercises();
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    useEffect(() => {
        fetchExercises();

        return () => {
            isMountedRef.current = false;
        };
    }, [fetchExercises]);

    return (
        <div>
            <div className="row mb-1">
                <div className="col-8 d-flex align-items-center">
                    <h1>Exercises</h1>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <button
                        className="btn btn-maroon"
                        onClick={() => {
                            fetchExercises();
                        }}
                        disabled={loading}
                    >
                        Refresh
                    </button>
                    <Link to="/Exercise/Create" className="btn btn-purple ms-1">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-plus-circle align-text-bottom"
                            viewBox="0 0 16 16"
                        >
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                    </Link>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="col-9">
                            Name
                        </th>
                        <th scope="col" className="col-3"></th>
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        exerciseList &&
                        exerciseList.map((exercise: ExerciseModel, index: number) => {
                            return (
                                <tr key={index}>
                                    <td style={{ wordBreak: "break-all" }}>{exercise.name}</td>
                                    <td style={{ wordBreak: "break-all" }}>
                                        <button
                                            className="btn btn-purple float-end"
                                            onClick={() => {
                                                setViewingExercise(exercise);
                                                setShowExerciseModal(true);
                                            }}
                                        >
                                            View
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                </tbody>
            </table>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <Modal centered={true} show={showExerciseModal} onHide={() => setShowExerciseModal(false)} aria-labelledby="exerciseModal">
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="exerciseModalLabel">Exercise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        <b>Exercise ID: </b> {viewingExercise.id} <br />
                        <b>Name: </b> {viewingExercise.name} <br />
                        <b>General Description: </b> {viewingExercise.general_description} <br />
                        <b>Video Link: </b> {viewingExercise.video_link} <br />
                        <b>Type: </b> {viewingExercise.type ?? ExerciseType.Other}
                        <br />
                        {viewingExercise.video_link && youtube_parser(viewingExercise.video_link) && (
                            <div className="d-flex justify-content-center">
                                <iframe
                                    className="mt-1"
                                    src={"https://www.youtube.com/embed/" + youtube_parser(viewingExercise.video_link)}
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </div>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            history.push("/Exercise/Edit?key=" + viewingExercise.key + "&id=" + viewingExercise.id);
                        }}
                        disabled={viewingExercise.id ? false : true}
                    >
                        Edit
                    </button>
                    <button className="btn btn-danger" onClick={() => setShowDeleteExerciseModal(true)} disabled={viewingExercise.id ? false : true}>
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
            <Modal centered={true} show={showDeleteExerciseModal} onHide={() => setShowDeleteExerciseModal(false)} aria-labelledby="deleteExerciseModal">
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="deleteExerciseModalLabel">Delete Exercise</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        Are you sure you want to delete this Exercise? <br />
                        Name: {viewingExercise.name}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary" onClick={() => setShowDeleteExerciseModal(false)}>
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            deleteExercise();
                            setShowDeleteExerciseModal(false);
                            setShowExerciseModal(false);
                            history.push("/Exercises?successMessage=Successfully+deleted+exercise.");
                        }}
                        disabled={viewingExercise.id ? false : true}
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Exercises;
