import { ReactComponent as PlayButton } from "./images/play-circle.svg";
import { ReactComponent as StopButton } from "./images/stop-circle.svg";
import Countdown, { CountdownRenderProps } from "react-countdown";
import { useState } from "react";

function RestTimer({ ...props }) {
    const DEFAULT_TIMER_LENGTH = 60;
    const [date, setDate] = useState(getDateTo());

    const renderer = ({ minutes, seconds, total, api }: CountdownRenderProps) => {
        return (
            <>
                <div className="row d-flex align-items-center">
                    <div className="col-9">
                        <div className="progress">
                            <div
                                className="progress-bar"
                                role="progressbar"
                                style={{
                                    width: (total / 1000 / DEFAULT_TIMER_LENGTH) * 100 + "%",
                                }}
                                aria-valuenow={(total / 1000 / DEFAULT_TIMER_LENGTH) * 100}
                                aria-valuemin={0}
                                aria-valuemax={100}
                            ></div>
                        </div>
                        <div className="d-flex justify-content-center">{minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0")}</div>
                    </div>
                    <div className="col-3 d-flex justify-content-center">
                        <button
                            className="btn btn-light"
                            type="button"
                            onClick={() => {
                                if (!api.isStarted()) {
                                    api.start();
                                } else {
                                    api.stop();
                                }
                            }}
                        >
                            {api.isStarted() ? <StopButton width={25} height={25} /> : <PlayButton width={25} height={25} />}
                        </button>
                    </div>
                </div>
            </>
        );
    };

    function getDateTo() {
        var date = new Date();
        date.setSeconds(date.getSeconds() + DEFAULT_TIMER_LENGTH);
        return date;
    }

    return (
        <div {...props}>
            <Countdown
                date={date}
                renderer={renderer}
                autoStart={false}
                onComplete={() => {
                    setDate(getDateTo());
                }}
            />
        </div>
    );
}

export default RestTimer;
