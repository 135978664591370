import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfAdmin, useQuery } from "./Constants";
import ExerciseModel, { ExerciseType } from "./models/ExerciseModel";

function EditExercise() {
    const [loading, setLoading] = useState(true);
    const [exercise, setExercise] = useState({} as ExerciseModel);
    const [exerciseKey, setExerciseKey] = useState("");
    const [exerciseId, setExerciseId] = useState("");

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const query = useQuery();
    const history = useHistory();

    const goBackToExercisesList = useCallback(
        (message: string = "") => {
            var redirectionUri = "/Exercises";
            if (message) {
                redirectionUri += "?" + message;
            }

            history.push(redirectionUri);
        },
        [history]
    );

    const fetchExercise = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfAdmin(getAccessTokenSilently))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Exercise/" + exerciseKey + "/" + exerciseId, {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. EditExercise (GET: Exercise): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: ExerciseModel) => {
                if (isMountedRef.current) {
                    if (data) {
                        setExercise(data);
                        setLoading(false);
                    } else {
                        goBackToExercisesList("errorMessage=Exercise+doesn't+exist.");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [exerciseId, exerciseKey, getAccessTokenSilently, isAuthenticated, isLoading, goBackToExercisesList]);

    async function editExercise() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        if (!(await checkIfAdmin(getAccessTokenSilently))) return;

        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Exercise/" + exercise.key + "/" + exercise.id, {
            method: "PUT",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify(exercise),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. EditExercise (PUT: Exercise): " + response.statusText + "(" + response.status + ")");
                }

                history.push("/Exercises?successMessage=Successfully+edited+exercise.");
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    function handleInputChange(event: any) {
        const target = event.target;
        const value = target.type === "checkbox" ? target.checked : target.value;
        const name = target.name;

        var newExercise: any = { ...exercise };
        if (newExercise) {
            newExercise[name] = value;
            setExercise(newExercise);
        }
    }

    function submitForm(event: any) {
        event.preventDefault();
        editExercise();
    }

    useEffect(() => {
        if (!query.has("key") || !query.has("id")) {
            goBackToExercisesList();
        } else {
            setExerciseKey(query.get("key") ?? "");
            setExerciseId(query.get("id") ?? "");
        }
    }, [query, goBackToExercisesList]);

    useEffect(() => {
        if (exerciseKey && exerciseId) {
            fetchExercise();
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [exerciseKey, exerciseId, fetchExercise]);

    return (
        <div>
            <h1>Edit Exercise</h1>
            <form onSubmit={submitForm}>
                <div className="mb-3">
                    <label htmlFor="keyInput" className="form-label">
                        Key
                    </label>
                    <input
                        type="name"
                        className="form-control"
                        id="keyInput"
                        placeholder="Key"
                        name="key"
                        value={exercise.key ?? ""}
                        onChange={handleInputChange}
                        disabled
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="idInput" className="form-label">
                        ID
                    </label>
                    <input
                        type="name"
                        className="form-control"
                        id="idInput"
                        placeholder="ID"
                        name="id"
                        value={exercise.id ?? ""}
                        onChange={handleInputChange}
                        disabled
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="nameInput" className="form-label">
                        Name
                    </label>
                    <input
                        type="name"
                        className="form-control"
                        id="nameInput"
                        placeholder="Name"
                        name="name"
                        value={exercise.name ?? ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="generalDescInput" className="form-label">
                        General Description
                    </label>
                    <textarea
                        className="form-control"
                        id="generalDescInput"
                        placeholder="General Description"
                        name="general_description"
                        value={exercise.general_description ?? ""}
                        onChange={handleInputChange}
                        rows={3}
                    ></textarea>
                </div>
                <div className="mb-3">
                    <label htmlFor="videoLinkInput" className="form-label">
                        Video Link
                    </label>
                    <input
                        type="name"
                        className="form-control"
                        id="videoLinkInput"
                        placeholder="Video Link"
                        name="video_link"
                        value={exercise.video_link ?? ""}
                        onChange={handleInputChange}
                    />
                </div>
                <div className="mb-3">
                    <label htmlFor="typeInput" className="form-label">
                        Exercise Type
                    </label>
                    <select
                        id="typeInput"
                        className="form-select"
                        aria-label="Select Exercise Type"
                        name="type"
                        value={exercise.type ?? ExerciseType.Other}
                        onChange={handleInputChange}
                    >
                        <option value={ExerciseType.Chest}>Chest</option>
                        <option value={ExerciseType.Back}>Back</option>
                        <option value={ExerciseType.Biceps}>Biceps</option>
                        <option value={ExerciseType.Triceps}>Triceps</option>
                        <option value={ExerciseType.Shoulders}>Shoulders</option>
                        <option value={ExerciseType.Legs}>Legs</option>
                        <option value={ExerciseType.Forearms}>Forearms</option>
                        <option value={ExerciseType.Abs}>Abs</option>
                        <option value={ExerciseType.Other}>Other</option>
                    </select>
                </div>
                <div>
                    <button type="button" className="btn btn-maroon" onClick={() => goBackToExercisesList()}>
                        Go Back
                    </button>
                    <div className="float-end">
                        <button type="submit" className="btn btn-purple" disabled={loading}>
                            {loading && (
                                <div className="spinner-border spinner-border-sm" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            )}
                            {!loading && <>Save</>}
                        </button>
                    </div>
                </div>
            </form>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default EditExercise;
