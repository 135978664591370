import { useAuth0 } from "@auth0/auth0-react";
import { Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { checkIfHasScopes } from "./Constants";

interface Props {
    children?: React.ReactNode | React.ReactNode[];
    scopes?: string[];
}

function ProtectedRoute({ children, scopes = [] }: Props) {
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    const [hasScopes, setHasScopes] = useState(true);

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            checkIfHasScopes(getAccessTokenSilently, scopes).then((value) => {
                setHasScopes(value);
            });
        }
    }, [getAccessTokenSilently, scopes, isLoading, isAuthenticated, hasScopes]);

    return (
        <>
            {!isLoading &&
                (isAuthenticated ? (
                    scopes.length > 0 ? (
                        hasScopes ? (
                            children
                        ) : (
                            <Redirect to="/?errorMessage=Admin+Only!+Please+log+in+as+an+admin+to+access+this+page." />
                        )
                    ) : (
                        children
                    )
                ) : (
                    <>
                        <Redirect to="/?errorMessage=Please+log+in+to+access+this+page." />
                    </>
                ))}
        </>
    );
}

export default ProtectedRoute;
