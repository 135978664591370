import { useAuth0 } from "@auth0/auth0-react";
import LoginButton, { LogoutButton, SignupButton } from "./AuthButtons";
import { useEffect } from "react";

interface Props {
    setContainerFluid: Function;
}

function Home({ setContainerFluid }: Props) {
    const { isLoading, isAuthenticated } = useAuth0();

    useEffect(() => {
        setContainerFluid(true);

        return () => {
            setContainerFluid(false);
        };
    }, [setContainerFluid]);

    return (
        <>
            <section id="banner-home">
                <div id="banner-overlay">
                    <div style={{ height: "75vh" }}></div>
                    <div className="d-flex justify-content-center">
                        <div className="container border rounded-3 mx-3 bg-white p-3 mb-4">
                            <h1 className="text-center">Home</h1>
                            <div>
                                An old friend in 2013 motivated me to start working out and get into this fitness journey. I only had 1 goal in mind and that
                                was to reinvent myself and be healthy physically and mentally. <br />
                                Over time with hard work and hustle I realized that consistency is the key. You can overcome any challenge if you have the right
                                mindset. <br /> <br />I have also learned that being and keeping fit is not going to be easy but it’s DO-ABLE!! <br />
                                Being fit does not mean you have 6 or 8 packs or a bomb ass body but instead being ‘fit’ meant being physically and mentally
                                healthy. It’s about working out at least 20-30mins per day (you can simply break down your works outs to 3 times a day - 10mins
                                each) 3-4times a week. <br />
                                And that is what I have learnt from my mentor @fitshatz. <br />
                                <br />
                                I know we are all so busy and time is of an essence but let’s not makes ‘EXCUSES’ when it comes to our own health. Start today
                                it’s never to late!!! <br />
                                <br />I want to use my skills, experience and my own journey to help other women and the youth. <br />
                                <br />
                                Let me end with one of my and my mentors’s favourite quote: <br />
                                "Moderation is key be it exercising or eating well."!! <br />
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="d-grid gap-2 col-12 col-sm-6 my-2">
                                    {!isLoading &&
                                        (isAuthenticated ? (
                                            <LogoutButton className="btn btn-danger ms-sm-1 me-sm-1" />
                                        ) : (
                                            <>
                                                <LoginButton className="btn btn-purple ms-sm-1 me-sm-1" />
                                                <SignupButton className="btn btn-purple ms-sm-1 me-sm-1" />
                                            </>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Home;
