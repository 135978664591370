import React from "react";
import { useHistory } from "react-router-dom";
import { AppState, Auth0Provider } from "@auth0/auth0-react";

interface Props {
    children: React.ReactNode | React.ReactNode[];
}

const Auth0ProviderWithHistory = ({ children }: Props) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN ?? "";
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID ?? "";
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE ?? "";
    const scope = process.env.REACT_APP_AUTH0_SCOPE ?? "";

    const history = useHistory();

    const onRedirectCallback = (appState?: AppState | undefined) => {
        history.push(appState?.returnTo || window.location.pathname);
    };

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            redirectUri={window.location.origin}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true}
            audience={audience}
            scope={scope}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
