import { ReactComponent as RunningShoe } from "./images/running.svg";
import { ReactComponent as Heart } from "./images/heart.svg";
import { ReactComponent as WeighingScaleIcon } from "./images/weighing-scale.svg";
import { ReactComponent as LeftArrow } from "./images/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right-circle.svg";
import { addMonths, getDayString, getMonthString, getUserStatsKey, parseDate } from "./Constants";
import UserStatsModel, { UserDailyStats } from "./models/UserStatsModel";

interface Props {
    loading: boolean;
    date: Date;
    setDate: Function;
    userStats: UserStatsModel;
}

function UserStats({ loading, date, setDate, userStats }: Props) {
    return (
        <div>
            <div className="row mb-1">
                <div className="col-8 d-flex align-items-center">
                    <h5 className="m-0">{getMonthString(date) + ", " + date.getFullYear()}</h5>
                </div>
                <div className="col-4 d-flex align-items-center justify-content-end">
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(addMonths(date, -1));
                        }}
                    >
                        <LeftArrow className="m-1" width="30" height="30" />
                    </button>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            var newDate = addMonths(date, 1);
                            if (newDate < new Date()) setDate(newDate);
                        }}
                        disabled={addMonths(date, 1) > new Date()}
                    >
                        <RightArrow className="m-1" width="30" height="30" />
                    </button>
                </div>
            </div>
            <table className="table">
                <thead>
                    <tr>
                        <th scope="col" className="col-6">
                            Date
                        </th>
                        <th scope="col" className="col-2">
                            <RunningShoe className="float-end" fill="#4bc81e" width="30" height="30" />
                        </th>
                        <th scope="col" className="col-2">
                            <Heart className="float-end" fill="#dc3545" width="30" height="30" />
                        </th>
                        <th scope="col" className="col-2">
                            <WeighingScaleIcon className="float-end" fill="#0dcaf0" width="30" height="30" />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {!loading &&
                        userStats &&
                        userStats[getUserStatsKey(date)] &&
                        (userStats[getUserStatsKey(date)].length > 0 ? (
                            userStats[getUserStatsKey(date)].map((userStats: UserDailyStats, index: number) => {
                                return (
                                    <tr key={index}>
                                        <th
                                            scope="row"
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                        >
                                            {parseDate(userStats.date).getDate()} - {getDayString(parseDate(userStats.date))}
                                        </th>
                                        <td
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                            className="text-end"
                                        >
                                            {userStats.steps}
                                        </td>
                                        <td
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                            className="text-end"
                                        >
                                            {userStats.heart_rate}
                                        </td>
                                        <td
                                            style={{
                                                wordBreak: "break-all",
                                            }}
                                            className="text-end"
                                        >
                                            {userStats.weight}
                                        </td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td>No Records</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        ))}
                </tbody>
            </table>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default UserStats;
