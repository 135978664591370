import ExerciseModel from "./ExerciseModel";

interface WorkoutModel {
    user_id: string;
    id: string;
    name: string;
    date: string;
    workout_file_name: string;
    status: string;
    type: string;
    exercises: WorkoutExerciseModel[][];
}

interface WorkoutExerciseModel extends ExerciseModel {
    is_custom: boolean;
    custom_description: string;
    min_sets: number;
    sets: SetModel[];
    previous_sets: SetModel[];
}

interface SetModel {
    order: number;
    reps: number | "";
    weight: number | "";
}

enum WorkoutStatus {
    /// <summary>
    /// Workout not started.
    /// </summary>
    NotStarted = "NotStarted",

    /// <summary>
    /// Workout started.
    /// </summary>
    Started = "Started",

    /// <summary>
    /// Workout completed.
    /// </summary>
    Completed = "Completed",
}

enum WorkoutType {
    /// <summary>
    /// The workout is a warm up.
    /// </summary>
    WarmUp = "WarmUp",

    /// <summary>
    /// The workout is the main workout.
    /// </summary>
    Main = "Main",

    /// <summary>
    /// The workout is a finisher.
    /// </summary>
    Finishers = "Finishers",

    /// <summary>
    /// The workout is a cool down.
    /// </summary>
    CoolDown = "CoolDown",
}

export default WorkoutModel;
export type { WorkoutExerciseModel, SetModel };
export { WorkoutStatus, WorkoutType };
