import jwt_decode from "jwt-decode";
import { useLocation } from "react-router-dom";
import WorkoutModel, { SetModel, WorkoutExerciseModel, WorkoutType } from "./models/WorkoutModel";
import { ReloadNotificationId } from "./ReloadNotification";

export const AzureFunctionUri = "https://jfit.azurewebsites.net/";

export function parseDate(dateStr: string) {
    var split = dateStr.split("-");
    return new Date(parseInt(split[2]), parseInt(split[1]) - 1, parseInt(split[0]));
}

export function dateToStr(date: Date) {
    var dd = String(date.getDate()).padStart(2, "0");
    var mm = String(date.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = date.getFullYear();

    return dd + "-" + mm + "-" + yyyy;
}

export function addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
}

export function addMonths(date: Date, months: number) {
    var newDate = new Date(date.valueOf());
    var d = newDate.getDate();
    newDate.setMonth(newDate.getMonth() + +months);
    if (newDate.getDate() !== d) {
        newDate.setDate(0);
    }
    return newDate;
}

export function getMonday(date: Date) {
    var newDate = new Date(date);
    while (newDate.getDay() !== 1) {
        newDate = addDays(newDate, -1);
    }
    return newDate;
}

const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
export function getDayString(date: Date) {
    return days[date.getDay()];
}

const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export function getMonthString(date: Date) {
    return months[date.getMonth()];
}

export async function checkIfAdmin(getAccessTokenSilently: Function) {
    var token = await getAccessTokenSilently();
    var decodedToken: any = jwt_decode(token);
    if (decodedToken.scope.includes("admin")) {
        return true;
    }
    return false;
}

export async function checkIfHasScopes(getAccessTokenSilently: Function, scopes: string[]) {
    var token = await getAccessTokenSilently();
    var decodedToken: any = jwt_decode(token);
    var foundAll = true;

    scopes.forEach((scope) => {
        if (!decodedToken.scope.includes(scope)) {
            foundAll = false;
        }
    });

    return foundAll;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}

// Ref: https://stackoverflow.com/questions/3452546/how-do-i-get-the-youtube-video-id-from-a-url
export function youtube_parser(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = url.match(regExp);
    return match && match[7].length === 11 ? match[7] : false;
}

export function getUserStatsKey(date: Date) {
    var month = date.getMonth();
    var year = date.getFullYear();
    return month + "-" + year;
}

// Get a date range string for user schedule page.
// e.g. 1 Nov 2021 - 7 Nov 2021
export function getDateRangeString(date: Date) {
    var startOfWeek = `${date.getDate()} ${getMonthString(date).substr(0, 3)} ${date.getFullYear()}`;
    var endDate = addDays(date, 6);
    var endOfWeek = `${endDate.getDate()} ${getMonthString(endDate).substr(0, 3)} ${endDate.getFullYear()}`;
    return startOfWeek + " - " + endOfWeek;
}

export function sortSetsInWorkout(workout: WorkoutModel) {
    if (workout.exercises.length > 0) {
        workout.exercises.forEach((exerciseGroup: WorkoutExerciseModel[]) => {
            exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
                exercise.sets.sort((a: SetModel, b: SetModel) => {
                    return a.order - b.order;
                });
            });
        });
    }
}

export function addMinSetsToWorkout(workout: WorkoutModel) {
    workout.exercises.forEach((exerciseGroup: WorkoutExerciseModel[]) => {
        var minSets = 1;

        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            if (exercise.min_sets > minSets) {
                minSets = exercise.min_sets;
            }
            if (exercise.sets.length > minSets) {
                minSets = exercise.sets.length;
            }
        });

        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            for (let i = 0; i < minSets; i++) {
                if (!exercise.sets[i]) {
                    exercise.sets[i] = {
                        order: i,
                        reps: 0,
                        weight: 0,
                    } as SetModel;
                }
            }
        });
    });
}

export function updatePreviousSets(workout: WorkoutModel) {
    workout.exercises.forEach((exerciseGroup: WorkoutExerciseModel[]) => {
        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            exercise.previous_sets = exercise.sets;
        });
    });
}

export function resetWorkoutSets(workout: WorkoutModel) {
    workout.exercises.forEach((exerciseGroup: WorkoutExerciseModel[]) => {
        exerciseGroup.forEach((exercise: WorkoutExerciseModel) => {
            exercise.sets = [] as SetModel[];
        });
    });
}

export function sortWorkoutsByType(workouts: WorkoutModel[]) {
    const typeOrder = Object.values(WorkoutType);
    return workouts.sort((a: WorkoutModel, b: WorkoutModel) => {
        if (a.type === b.type) {
            if (a.name.toLowerCase() === b.name.toLowerCase()) {
                return 0;
            }
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        }
        const order = typeOrder.indexOf(a.type as WorkoutType) - typeOrder.indexOf(b.type as WorkoutType);
        return order;
    });
}

export function moveExerciseUp(workout: WorkoutModel, workoutExerciseGroupIndex: number, workoutExerciseIndex: number) {
    if (workoutExerciseIndex <= 0) return workout;

    var newWorkout = { ...workout };

    // The exercise being moved up.
    var exercise1 = workout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex];
    // The exercise which will be forced down.
    var exercise2 = workout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex - 1];

    if (!exercise1 || !exercise2) return workout;

    newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex - 1] = exercise1;
    newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex] = exercise2;

    return newWorkout;
}

export function moveExerciseDown(workout: WorkoutModel, workoutExerciseGroupIndex: number, workoutExerciseIndex: number) {
    if (workoutExerciseIndex >= workout.exercises[workoutExerciseGroupIndex].length - 1) return workout;

    var newWorkout = { ...workout };

    // The exercise being moved down.
    var exercise1 = workout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex];
    //The exercise which will be forced up.
    var exercise2 = workout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex + 1];

    if (!exercise1 || !exercise2) return workout;

    newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex + 1] = exercise1;
    newWorkout.exercises[workoutExerciseGroupIndex][workoutExerciseIndex] = exercise2;

    return newWorkout;
}

export function moveExerciseGroupUp(workout: WorkoutModel, workoutExerciseGroupIndex: number) {
    if (workoutExerciseGroupIndex <= 0) return workout;

    var newWorkout = { ...workout };

    // The workout being moved up.
    var workout1 = workout.exercises[workoutExerciseGroupIndex];
    // The workout which will be forced down.
    var workout2 = workout.exercises[workoutExerciseGroupIndex - 1];

    if (!workout1 || !workout2) return workout;

    newWorkout.exercises[workoutExerciseGroupIndex - 1] = workout1;
    newWorkout.exercises[workoutExerciseGroupIndex] = workout2;

    return newWorkout;
}

export function moveExerciseGroupDown(workout: WorkoutModel, workoutExerciseGroupIndex: number) {
    if (workoutExerciseGroupIndex >= workout.exercises.length - 1) return workout;

    var newWorkout = { ...workout };

    // The workout being moved down.
    var workout1 = workout.exercises[workoutExerciseGroupIndex];
    // The workout which will be forced up.
    var workout2 = workout.exercises[workoutExerciseGroupIndex + 1];

    if (!workout1 || !workout2) return workout;

    newWorkout.exercises[workoutExerciseGroupIndex + 1] = workout1;
    newWorkout.exercises[workoutExerciseGroupIndex] = workout2;

    return newWorkout;
}

export async function skipWaitingAndReload() {
    var registeredSW = await navigator.serviceWorker.getRegistration();
    if (registeredSW) {
        if (registeredSW.waiting) {
            registeredSW.waiting.postMessage({ type: "SKIP_WAITING" });
        } else {
            window.location.reload();
        }
    }
}

export function UpdateReloadNotificationStorageValue(value: boolean) {
    localStorage.setItem(ReloadNotificationId, value ? "true" : "false");
    var showReloadNotificationEvent = new Event(ReloadNotificationId);
    window.dispatchEvent(showReloadNotificationEvent);
}
