import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { AzureFunctionUri, resetWorkoutSets, updatePreviousSets } from "./Constants";
import { Auth0User } from "./models/Auth0Users";
import WorkoutModel, { WorkoutStatus } from "./models/WorkoutModel";

interface Props {
    workout: WorkoutModel;
    closeModal: Function;
}
function DuplicateWorkoutModal({ workout, closeModal }: Props) {
    const [show, setShow] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [isSuccess, setIsSuccess] = useState(true);
    const [loadingUsers, setLoadingUsers] = useState(true);
    const [savingWorkout, setSavingWorkout] = useState(false);
    const [users, setUsers] = useState([] as Auth0User[]);
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedDate, setSelectedDate] = useState("");

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRefUsers = useRef(true);
    const isMountedRefDuplicateModal = useRef(true);

    const fetchUsers = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUsers.current = true;
        setLoadingUsers(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/AllUsers", {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. DuplicateWorkoutModal (GET: AllUsers): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data: Auth0User[]) => {
                if (isMountedRefUsers.current) {
                    setUsers(data);
                    setLoadingUsers(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRefUsers.current) setLoadingUsers(false);
            });
    }, [getAccessTokenSilently, isAuthenticated, isLoading]);

    function submitForm(event: any) {
        event.preventDefault();
        var newWorkout = { ...workout };

        var split = selectedDate.split("-");
        var newDate = split[2] + "-" + split[1] + "-" + split[0];
        newWorkout.date = newDate;

        newWorkout.user_id = selectedUserId;
        newWorkout.id = "";
        newWorkout.status = WorkoutStatus.NotStarted;
        newWorkout.workout_file_name = "";

        // Save Sets as Previous Sets
        updatePreviousSets(newWorkout);

        // Reset Sets when duplicating
        resetWorkoutSets(newWorkout);

        submitWorkout(newWorkout);
    }

    const submitWorkout = useCallback(
        async (newWorkout: WorkoutModel) => {
            if (isLoading || (!isLoading && !isAuthenticated)) return;
            isMountedRefDuplicateModal.current = true;
            setSavingWorkout(true);

            const accessToken = await getAccessTokenSilently();

            fetch(AzureFunctionUri + "api/Workout/" + encodeURIComponent(newWorkout.user_id) + "/" + newWorkout.id, {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + accessToken,
                },
                body: JSON.stringify(newWorkout),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("Network response was not ok. CreateWorkout (PUT: Workout): " + response.statusText + "(" + response.status + ")");
                    }
                    if (isMountedRefDuplicateModal) {
                        setSavingWorkout(false);
                        setIsSuccess(true);
                        setShowAlert(true);
                    }
                })
                .catch((error) => {
                    console.error(error);
                    if (isMountedRefDuplicateModal.current) {
                        setSavingWorkout(false);
                        setIsSuccess(false);
                        setShowAlert(true);
                    }
                });
        },
        [getAccessTokenSilently, isAuthenticated, isLoading]
    );

    function selectUser(event: any) {
        setSelectedUserId(event.target.value);
    }

    function handleDateChange(event: any) {
        const target = event.target;
        const value = target.value;

        setSelectedDate(value);
    }

    useEffect(() => {
        if (!selectedUserId) {
            setSelectedUserId(workout.user_id);
        }
    }, [selectedUserId, workout.user_id]);

    useEffect(() => {
        if (!selectedDate) {
            var split = workout.date.split("-");
            var date = split[2] + "-" + split[1] + "-" + split[0];
            setSelectedDate(date);
        }
    }, [selectedDate, workout.date]);

    useEffect(() => {
        fetchUsers();

        return () => {
            isMountedRefUsers.current = false;
        };
    }, [fetchUsers]);

    return (
        <Modal
            size="sm"
            show={show}
            centered
            onHide={() => {
                setShow(false);
            }}
            onExited={() => {
                closeModal();
            }}
        >
            <Modal.Header closeButton>
                <Modal.Title>Dupliate Workout</Modal.Title>
            </Modal.Header>
            <form onSubmit={submitForm}>
                <Modal.Body>
                    {showAlert && (
                        <>
                            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                                <symbol id="check-circle-fill" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                </symbol>
                                <symbol id="exclamation-triangle-fill" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                                </symbol>
                            </svg>
                            <Alert
                                variant={isSuccess ? "success" : "danger"}
                                dismissible={true}
                                show={showAlert}
                                onClose={() => {
                                    setShowAlert(false);
                                }}
                            >
                                <svg className="bi flex-shrink-0 me-2" width="30" height="30" role="img" aria-label="Alert Icon:">
                                    <use xlinkHref={isSuccess ? "#check-circle-fill" : "#exclamation-triangle-fill"} />
                                </svg>
                                {isSuccess ? "Successfully duplicated workout." : "An error occurred while duplicating workout."}
                            </Alert>
                        </>
                    )}
                    <div>
                        User: <br />{" "}
                    </div>
                    {!loadingUsers && (
                        <select
                            className="form-select"
                            aria-label="Users List Select"
                            onChange={(e) => selectUser(e)}
                            disabled={loadingUsers}
                            defaultValue={workout.user_id}
                        >
                            {users.map((user: Auth0User, index: number) => {
                                return (
                                    <option key={index} value={user.user_id}>
                                        {user.name}
                                    </option>
                                );
                            })}
                        </select>
                    )}
                    {loadingUsers && (
                        <div className="spinner-border spinner-border-sm" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    )}
                    <div className="mb-3">
                        <label htmlFor="dateInput" className="form-label">
                            Date
                        </label>
                        <input
                            type="date"
                            className="form-control"
                            id="dateInput"
                            placeholder="Date"
                            name="date"
                            value={selectedDate}
                            onChange={handleDateChange}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-maroon"
                        type="button"
                        onClick={() => {
                            setShow(false);
                        }}
                    >
                        Cancel
                    </button>
                    <button className="btn btn-purple" type="submit" disabled={loadingUsers || savingWorkout}>
                        {(loadingUsers || savingWorkout) && (
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        )}
                        {!loadingUsers && !savingWorkout && <>Save</>}
                    </button>
                </Modal.Footer>
            </form>
        </Modal>
    );
}

export default DuplicateWorkoutModal;
