import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useRef, useState } from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { AzureFunctionUri } from "./Constants";

function Profile() {
    const { user, isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);

    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [defaultName, setDefaultName] = useState("");
    const [showPasswordPopover, setShowPasswordPopover] = useState(false);

    function handleNameChange(event: any) {
        setName(event.target.value);
    }

    async function updateName(event: any) {
        event.preventDefault();
        isMountedRef.current = true;
        setLoading(true);

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Profile/" + encodeURIComponent(user?.sub), {
            method: "PATCH",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify({
                name: name,
            }),
        })
            .then(async (response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Profile (PATCH: Profile): " + response.statusText + "(" + response.status + ")");
                }
                if (isMountedRef.current) {
                    await getAccessTokenSilently({ ignoreCache: true }).then(() => {
                        if (isMountedRef.current) setDefaultName(user?.name ?? "");
                    });
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    const passwordPopover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Change Password?</Popover.Header>
            <Popover.Body>
                To change your password, click on <strong>forgot your password</strong> in the Log in page (Your email must be verified to do so)
            </Popover.Body>
        </Popover>
    );

    useEffect(() => {
        setName(user?.name ?? "");
        setDefaultName(user?.name ?? "");

        return () => {
            isMountedRef.current = false;
        };
    }, [setName, user]);

    return (
        <div>
            <h1>Profile</h1>
            <>
                <div className="d-flex justify-content-center">
                    <div className="col-12 col-md-6">
                        <div>
                            {user?.picture && (
                                <div className="d-flex justify-content-center">
                                    <img src={user?.picture} className="rounded-circle border-white" alt="Profile Pic" />
                                </div>
                            )}
                        </div>
                        <form onSubmit={updateName}>
                            <div className="mb-3">
                                <label htmlFor="emailInput" className="form-label">
                                    Email address ({user?.email_verified ? "" : "Not "}
                                    Verified)
                                </label>
                                <input type="email" className="form-control" id="emailInput" placeholder="name@jfit.com" value={user?.email ?? ""} readOnly />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="nameInput" className="form-label">
                                    Name
                                </label>
                                <input type="name" className="form-control" id="nameInput" placeholder="name" value={name} onChange={handleNameChange} />
                            </div>
                            <div className="mb-3">
                                <span className="me-2">Password</span>
                                <OverlayTrigger
                                    trigger="click"
                                    overlay={passwordPopover}
                                    show={showPasswordPopover}
                                    onToggle={() => {
                                        setShowPasswordPopover(!showPasswordPopover);
                                    }}
                                    rootClose
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        fill="currentColor"
                                        className="bi bi-info-circle-fill align-text-bottom"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                    </svg>
                                </OverlayTrigger>
                            </div>
                            <button type="submit" className="btn btn-purple float-end" disabled={name === defaultName || loading}>
                                {loading && (
                                    <div className="spinner-border spinner-border-sm" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                )}
                                {!loading && <>Save</>}
                            </button>
                        </form>
                    </div>
                </div>
            </>
        </div>
    );
}

export default Profile;
