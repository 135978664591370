import { useEffect, useState, useCallback, useRef } from "react";
import { ReactComponent as WeighingScaleIcon } from "./images/weighing-scale.svg";
import { ReactComponent as Pencil } from "./images/pencil.svg";
import { ReactComponent as RunningShoe } from "./images/running.svg";
import { ReactComponent as Heart } from "./images/heart.svg";
import { ReactComponent as LeftArrow } from "./images/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right-circle.svg";
import DashboardModel, { DashboardDataModel } from "./models/DashboardModel";
import { addDays, AzureFunctionUri, dateToStr, getDayString, getMonday } from "./Constants";
import { Button, Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";

function Dashboard() {
    const [dashboardModel, setDashboardModel] = useState({} as DashboardDataModel);
    const [viewingDate, setViewingDate] = useState(new Date());
    const [steps, setSteps] = useState(0);
    const [showStepsModal, setShowStepsModal] = useState(false);
    const [heartRate, setHeartRate] = useState(0);
    const [showHeartRateModal, setShowHeartRateModal] = useState(false);
    const [weight, setWeight] = useState(0);
    const [showWeightModal, setShowWeightModal] = useState(false);
    const [loading, setLoading] = useState(true);

    const [stepsInputRef, setStepsInputFocus] = useFocus();
    const [heartRateInputRef, setHeartRateInputFocus] = useFocus();
    const [weightInputRef, setWeightInputFocus] = useFocus();

    const { getAccessTokenSilently, isLoading, isAuthenticated, user } = useAuth0();

    const isMountedRef = useRef(true);

    function previousDay() {
        setViewingDate(addDays(viewingDate, -1));
    }

    function nextDay() {
        var newDate = addDays(viewingDate, 1);
        if (newDate < new Date()) {
            setViewingDate(addDays(viewingDate, 1));
        }
    }

    const fetchWeeklyStats = useCallback(async () => {
        isMountedRef.current = true;
        setLoading(true);
        var monday = getMonday(viewingDate);

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/WeeklyStats/" + encodeURIComponent(user.sub) + "/" + dateToStr(monday), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Dashboard (GET: WeeklyStats): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data) => {
                const updatedModel = {
                    ...dashboardModel,
                };
                data.forEach((element: DashboardModel) => {
                    updatedModel[element.date] = element;
                });

                for (let i = 0; i < 7; i++) {
                    var stats = updatedModel[dateToStr(monday)];
                    if (!stats) {
                        const defaultStats: DashboardModel = {
                            user_id: user.sub ?? "",
                            date: dateToStr(monday),
                            steps: 0,
                            heart_rate: 0,
                            weight: 0,
                        };
                        updatedModel[dateToStr(monday)] = defaultStats;
                    }
                    monday = addDays(monday, 1);
                }
                if (isMountedRef.current) {
                    setDashboardModel(updatedModel);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) setLoading(false);
            });
    }, [dashboardModel, viewingDate, getAccessTokenSilently, isLoading, isAuthenticated, user]);

    async function fetchDailyStats() {
        isMountedRef.current = true;
        setLoading(true);

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/DailyStats/" + encodeURIComponent(user.sub) + "/" + dateToStr(viewingDate), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Dashboard (GET: DailyStats): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data) => {
                var updatedModel = { ...dashboardModel };
                updatedModel[data.date] = data;

                if (isMountedRef.current) {
                    setDashboardModel(updatedModel);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    async function updateDailyStats() {
        isMountedRef.current = true;
        setLoading(true);
        var dailyStatsJson = dashboardModel[dateToStr(viewingDate)];
        if (!dailyStatsJson || !dailyStatsJson.user_id || !dailyStatsJson.date) {
            return;
        }

        if (!isLoading && !isAuthenticated) return;
        if (!user?.sub) return;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/DailyStats/" + encodeURIComponent(user.sub) + "/" + dateToStr(viewingDate), {
            method: "PUT",
            cache: "no-cache",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + accessToken,
            },
            body: JSON.stringify(dailyStatsJson),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. Dashboard (PUT: DailyStats): " + response.statusText + "(" + response.status + ")");
                }
                if (isMountedRef.current) {
                    fetchDailyStats();
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
            });
    }

    useEffect(() => {
        if (!isLoading && isAuthenticated) {
            var stats = dashboardModel[dateToStr(viewingDate)];
            if (!stats) {
                fetchWeeklyStats();
            }

            return () => {
                isMountedRef.current = false;
            };
        }
    }, [viewingDate, fetchWeeklyStats, dashboardModel, isLoading, isAuthenticated]);

    function handleChange(event: any, callback: any) {
        const value = event.target.value;
        var valueFloat = parseFloat(value);
        if (valueFloat || valueFloat === 0) {
            callback(valueFloat);
        } else {
            callback("");
        }
    }

    function submitSteps(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var stats = dashboardModel[dateToStr(viewingDate)];
        if (stats && stats.steps !== steps && steps >= 0) {
            stats.steps = steps;
            updateDailyStats();
        }

        setSteps(0);
        setShowStepsModal(false);
    }

    function submitHeartRate(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var stats = dashboardModel[dateToStr(viewingDate)];
        if (stats && stats.heart_rate !== heartRate && heartRate >= 0) {
            stats.heart_rate = heartRate;
            updateDailyStats();
        }

        setHeartRate(0);
        setShowHeartRateModal(false);
    }

    function submitWeight(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        var stats = dashboardModel[dateToStr(viewingDate)];
        if (stats && stats.weight !== weight && weight >= 0) {
            stats.weight = weight;
            updateDailyStats();
        }

        setWeight(0);
        setShowWeightModal(false);
    }

    return (
        <div>
            <h1>Dashboard</h1>
            <>
                <div className="row mb-1">
                    <div className="col-8 d-flex align-items-center">
                        <h5 className="m-0">{getDayString(viewingDate) + ", " + dateToStr(viewingDate)}</h5>
                    </div>
                    <div className="col-4 d-flex align-items-center justify-content-end">
                        <button className="btn p-0" onClick={() => previousDay()}>
                            <LeftArrow className="m-1" width="30" height="30" />
                        </button>
                        <button className="btn p-0" onClick={() => nextDay()}>
                            <RightArrow className="m-1" width="30" height="30" />
                        </button>
                    </div>
                </div>
                <div className="row row-cols-1 row-cols-md-2">
                    <div className="col">
                        <div className="card border-lime border-3 mb-3" style={{ borderRadius: "1rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <RunningShoe fill="#4bc81e" width="50" height="50" />
                                    </div>
                                    <div className="col-6 text-center">
                                        <h5 className="card-title">Steps</h5>
                                        {loading && (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                        {!loading && <div className="fw-bold fs-1">{dashboardModel[dateToStr(viewingDate)]?.steps ?? 0}</div>}
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                !loading && setShowStepsModal(true);
                                            }}
                                        >
                                            <Pencil width="30" height="30" fill="grey" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-danger border-3 mb-3" style={{ borderRadius: "1rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <Heart fill="#dc3545" width="50" height="50" />
                                    </div>
                                    <div className="col-6 text-center">
                                        <h5 className="card-title">Heart Rate</h5>
                                        {loading && (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                        {!loading && <div className="fw-bold fs-1">{(dashboardModel[dateToStr(viewingDate)]?.heart_rate ?? 0) + " bpm"}</div>}
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                !loading && setShowHeartRateModal(true);
                                            }}
                                        >
                                            <Pencil width="30" height="30" fill="grey" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card border-info border-3 mb-3" style={{ borderRadius: "1rem" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <WeighingScaleIcon fill="#0dcaf0" width="50" height="50" />
                                    </div>
                                    <div className="col-6 text-center">
                                        <h5 className="card-title">Weight</h5>
                                        {loading && (
                                            <div className="spinner-border spinner-border-sm" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        )}
                                        {!loading && <div className="fw-bold fs-1">{(dashboardModel[dateToStr(viewingDate)]?.weight ?? 0) + " kg"}</div>}
                                    </div>
                                    <div className="col-3 d-flex align-items-center justify-content-center">
                                        <button
                                            className="btn"
                                            onClick={() => {
                                                !loading && setShowWeightModal(true);
                                            }}
                                        >
                                            <Pencil width="30" height="30" fill="grey" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            <Modal
                show={showStepsModal}
                onHide={() => {
                    setShowStepsModal(false);
                }}
                onShow={() => {
                    setSteps(dashboardModel[dateToStr(viewingDate)]?.steps ?? 0);
                    setStepsInputFocus();
                }}
                centered={true}
                size="sm"
            >
                <form onSubmit={submitSteps}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Steps</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            className="form-control"
                            type="number"
                            min={0}
                            ref={stepsInputRef}
                            value={steps}
                            onChange={(e) => {
                                handleChange(e, setSteps);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="maroon"
                            onClick={() => {
                                setShowStepsModal(false);
                                setSteps(0);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="purple" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal
                show={showHeartRateModal}
                onHide={() => {
                    setShowHeartRateModal(false);
                }}
                onShow={() => {
                    setHeartRate(dashboardModel[dateToStr(viewingDate)]?.heart_rate ?? 0);
                    setHeartRateInputFocus();
                }}
                centered={true}
                size="sm"
            >
                <form onSubmit={submitHeartRate}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Heart Rate (bpm)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            className="form-control"
                            type="number"
                            min={0}
                            ref={heartRateInputRef}
                            value={heartRate}
                            onChange={(e) => {
                                handleChange(e, setHeartRate);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="maroon"
                            onClick={() => {
                                setShowHeartRateModal(false);
                                setHeartRate(0);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="purple" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
            <Modal
                show={showWeightModal}
                onHide={() => {
                    setShowWeightModal(false);
                }}
                onShow={() => {
                    setWeight(dashboardModel[dateToStr(viewingDate)]?.weight ?? 0);
                    setWeightInputFocus();
                }}
                centered={true}
                size="sm"
            >
                <form onSubmit={submitWeight}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Weight (kg)</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <input
                            className="form-control"
                            type="number"
                            min={0}
                            ref={weightInputRef}
                            value={weight}
                            step="any"
                            onChange={(e) => {
                                handleChange(e, setWeight);
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="maroon"
                            onClick={() => {
                                setShowWeightModal(false);
                                setWeight(0);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="purple" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </div>
    );
}

export default Dashboard;

const useFocus = () => {
    const htmlElRef = useRef<HTMLInputElement>(null);
    const setFocus = () => {
        htmlElRef.current && htmlElRef.current.focus();
    };

    return [htmlElRef, setFocus] as const;
};
