import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Modal, Nav } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, dateToStr, getMonday, getUserStatsKey, parseDate, sortWorkoutsByType, useQuery } from "./Constants";
import { Auth0User } from "./models/Auth0Users";
import { UserRoles } from "./models/UserRoleModel";
import UserStatsModel, { UserDailyStats } from "./models/UserStatsModel";
import WorkoutModel from "./models/WorkoutModel";
import UserSchedule from "./UserSchedule";
import UserStats from "./UserStats";

enum UserTabs {
    DailyStats,
    Schedule,
}

function User() {
    const [navIndex, setNavIndex] = useState(UserTabs.DailyStats);
    const [userId, setUserId] = useState("");
    const [user, setUser] = useState({} as Auth0User);
    const [loadingUser, setLoadingUser] = useState(true);
    const isMountedRefUser = useRef(true);

    const [loadingStats, setLoadingStats] = useState(true);
    const [statsDate, setStatsDate] = useState(new Date());
    const [userStats, setUserStats] = useState({} as UserStatsModel);
    const isMountedRefUserStats = useRef(true);

    const [loadingSchedule, setLoadingSchedule] = useState(true);
    const [scheduleDate, setScheduleDate] = useState(getMonday(new Date()));
    const [userSchedule, setUserSchedule] = useState({} as { [key: string]: WorkoutModel[][] });
    const isMountedRefUserWorkouts = useRef(true);

    const [showUserSettingsModal, setShowUserSettingsModal] = useState(false);
    const [showActivateUserModal, setShowActivateUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const query = useQuery();

    const fetchUser = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUser.current = true;

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/User/" + encodeURIComponent(userId), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. User (GET: User): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: Auth0User) => {
                if (isMountedRefUser.current) {
                    setUser(data);
                    setLoadingUser(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }, [getAccessTokenSilently, isLoading, isAuthenticated, userId]);

    const fetchUserStats = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUserStats.current = true;
        setLoadingStats(true);
        var queryDate = new Date(statsDate.valueOf());

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/MonthlyStats/" + encodeURIComponent(userId) + "/" + dateToStr(statsDate), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. User (GET: MonthlyStats): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: UserDailyStats[]) => {
                const updatedModel = { ...userStats };
                if (data.length > 0) {
                    var date = parseDate(data[0].date);
                    var key = getUserStatsKey(date);

                    updatedModel[key] = data;
                } else {
                    key = getUserStatsKey(queryDate);
                    updatedModel[key] = [] as UserDailyStats[];
                }
                if (isMountedRefUserStats.current) {
                    setUserStats(updatedModel);
                    setLoadingStats(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRefUserStats.current) setLoadingStats(false);
            });
    }, [statsDate, getAccessTokenSilently, isAuthenticated, isLoading, userId, userStats]);

    const fetchWorkouts = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUserWorkouts.current = true;
        setLoadingSchedule(true);
        var monday = getMonday(scheduleDate);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/WeeklyWorkout/" + encodeURIComponent(userId) + "/" + dateToStr(scheduleDate), {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. User (GET: WeeklyWorkout): ${response.statusText}(${response.status})`);
                }
                return response.json();
            })
            .then((data: WorkoutModel[]) => {
                const updatedModel = { ...userSchedule };
                var workoutWeek = [] as WorkoutModel[][];

                var dailyWorkouts = {} as { [key: string]: WorkoutModel[] };
                var workoutIndexes: string[] = [];
                if (data.length > 0) {
                    data.forEach((workout: WorkoutModel) => {
                        if (dailyWorkouts[workout.date]) {
                            dailyWorkouts[workout.date].push(workout);
                        } else {
                            workoutIndexes.push(workout.date);
                            dailyWorkouts[workout.date] = [workout];
                        }
                    });
                }

                workoutIndexes = workoutIndexes.sort((a: string, b: string) => {
                    return parseDate(a).valueOf() - parseDate(b).valueOf();
                });

                workoutIndexes.forEach((workoutDate: string) => {
                    var workouts = dailyWorkouts[workoutDate];
                    workouts = sortWorkoutsByType(workouts);
                    workoutWeek.push(workouts);
                });

                updatedModel[dateToStr(monday)] = workoutWeek;

                if (isMountedRefUserWorkouts.current) {
                    setUserSchedule(updatedModel);
                    setLoadingSchedule(false);
                }
            })
            .catch((error) => {
                console.error("Error: ", error);
                if (isMountedRefUserWorkouts.current) setLoadingSchedule(false);
            });
    }, [scheduleDate, userId, userSchedule, getAccessTokenSilently, isAuthenticated, isLoading]);

    useEffect(() => {
        if (query.has("userId")) {
            setUserId(query.get("userId") ?? "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (userId && user && !user.name) {
            fetchUser();
        }

        return () => {
            isMountedRefUser.current = false;
        };
    }, [fetchUser, userId, user]);

    useEffect(() => {
        var key = getUserStatsKey(statsDate);

        if (!userStats[key] && userId) {
            fetchUserStats();
        }

        return () => {
            isMountedRefUserStats.current = false;
        };
    }, [fetchUserStats, statsDate, userId, userStats]);

    useEffect(() => {
        if (userId) {
            var workout = userSchedule[dateToStr(scheduleDate)];
            if (!workout) {
                fetchWorkouts();
            }
        }

        return () => {
            isMountedRefUserWorkouts.current = false;
        };
    }, [fetchWorkouts, scheduleDate, userSchedule, userId]);

    return (
        <div>
            {user && (user.name ? <h1>{user.name}</h1> : <h1>User</h1>)}
            <div className="d-flex justify-content-between mb-2">
                <h5>
                    <span className="align-middle">Membership Status: {user.role === UserRoles.User ? "Active" : "Inactive"}</span>
                </h5>
                {/* <button className="btn btn-primary" onClick={() => setShowActivateUserModal(true)} disabled={loadingUser}>
                    {user.role === UserRoles.User ? "Deactivate" : "Activate"}
                </button> */}
                <button className="btn btn-primary" onClick={() => setShowUserSettingsModal(true)} disabled={loadingUser}>
                    Settings
                </button>
            </div>

            <Nav
                className="mb-2"
                variant="tabs"
                fill={true}
                defaultActiveKey={navIndex}
                onSelect={(e) => {
                    var index = 0;
                    if (e) index = parseInt(e);
                    setNavIndex(index);
                }}
            >
                <Nav.Item>
                    <Nav.Link eventKey={UserTabs.DailyStats} aria-expanded={navIndex === UserTabs.DailyStats} aria-controls="daily">
                        Daily Stats
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey={UserTabs.Schedule} aria-expanded={navIndex === UserTabs.Schedule} aria-controls="schedule">
                        Schedule
                    </Nav.Link>
                </Nav.Item>
            </Nav>
            {navIndex === UserTabs.DailyStats && <UserStats loading={loadingStats} date={statsDate} setDate={setStatsDate} userStats={userStats} />}
            {navIndex === UserTabs.Schedule && (
                <UserSchedule
                    loading={loadingSchedule}
                    setLoading={setLoadingSchedule}
                    date={scheduleDate}
                    setDate={setScheduleDate}
                    userWorkouts={userSchedule}
                    userId={userId}
                    fetchWorkouts={fetchWorkouts}
                    isMountedRefUserSchedule={isMountedRefUserWorkouts}
                />
            )}
            <UserSettingsModal
                show={showUserSettingsModal}
                handleClose={() => setShowUserSettingsModal(false)}
                user={user}
                setShowActivateUserModal={setShowActivateUserModal}
                setShowDeleteUserModal={setShowDeleteUserModal}
            />
            <ActivateUserModal
                show={showActivateUserModal}
                handleClose={() => setShowActivateUserModal(false)}
                user={user}
                fetchUser={fetchUser}
                setLoadingUser={(value) => setLoadingUser(value)}
            />
            <DeleteUserModal show={showDeleteUserModal} handleClose={() => setShowDeleteUserModal(false)} user={user} />
        </div>
    );
}

interface UserSettingsModalProps {
    show: boolean;
    handleClose: () => void;
    user: Auth0User;
    setShowActivateUserModal: any;
    setShowDeleteUserModal: any;
}

function UserSettingsModal({ show, handleClose, user, setShowActivateUserModal, setShowDeleteUserModal }: UserSettingsModalProps) {
    return (
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header closeButton>
                <Modal.Title>User Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-between align-items-center mb-2">
                    <span className="align-middle">{user.role === UserRoles.User ? "Deactivate" : "Activate"} membership:</span>
                    <button className={"btn btn-" + (user.role === UserRoles.User ? "danger" : "primary")} onClick={() => setShowActivateUserModal(true)}>
                        {user.role === UserRoles.User ? "Deactivate" : "Activate"}
                    </button>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                    <div>Delete User:</div>
                    <div>
                        <button className="btn btn-danger" onClick={() => setShowDeleteUserModal(true)}>
                            Delete
                        </button>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

interface ActivateUserModalProps {
    show: boolean;
    handleClose: () => void;
    user: Auth0User;
    fetchUser: () => Promise<void>;
    setLoadingUser: (value: boolean) => void;
}

function ActivateUserModal({ show, handleClose, user, fetchUser, setLoadingUser }: ActivateUserModalProps) {
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();

    async function activateUser() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        setLoadingUser(true);

        const accessToken = await getAccessTokenSilently();

        fetch(
            AzureFunctionUri +
                "api/UserRoles/" +
                encodeURIComponent(user.user_id) +
                (user.role === UserRoles.User ? `/${UserRoles.Other}` : `/${UserRoles.User}`),
            {
                method: "PUT",
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. User (PUT: UserRoles): ${response.statusText}(${response.status})`);
                }
                fetchUser();
            })
            .catch((error) => {
                console.error("Error: ", error);
            });
    }

    return (
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header closeButton>
                <Modal.Title>{user.role === UserRoles.User ? "Deactivate" : "Activate"} User</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to {user.role === UserRoles.User ? "deactivate" : "activate"} this user?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant={user.role === UserRoles.User ? "danger" : "primary"}
                    onClick={() => {
                        activateUser();
                        handleClose();
                    }}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

interface DeleteUserModalProps {
    show: boolean;
    handleClose: () => void;
    user: Auth0User;
}

function DeleteUserModal({ show, handleClose, user }: DeleteUserModalProps) {
    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    async function deleteUser() {
        if (isLoading || (!isLoading && !isAuthenticated)) return;

        const accessToken = await getAccessTokenSilently();
        fetch(AzureFunctionUri + "api/User/" + encodeURIComponent(user.user_id), {
            method: "DELETE",
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Network response was not ok. User (DELETE: User): ${response.statusText}(${response.status})`);
                }
                history.push("/Users?successMessage=Successfully+deleted+user+and+their+data.");
            })
            .catch((error) => {
                console.error("Error: ", error);
                history.push("/Users?errorMessage=Failed+to+fully+delete+user.+Some+data+might+be+leftover.");
            });
    }

    return (
        <Modal show={show} onHide={handleClose} centered size="sm">
            <Modal.Header closeButton>
                <Modal.Title>Delete User</Modal.Title>
            </Modal.Header>
            <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button
                    variant={"danger"}
                    onClick={() => {
                        deleteUser();
                        handleClose();
                    }}
                >
                    Confirm
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default User;
export { ActivateUserModal };
