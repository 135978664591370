export default interface ExerciseModel {
    key: string;
    id: string;
    name: string;
    general_description: string;
    video_link: string;
    type: string;
}

enum ExerciseType {
    /// <summary>
    /// Chest exercise.
    /// </summary>
    Chest = "Chest",

    /// <summary>
    /// Back exercise.
    /// </summary>
    Back = "Back",

    /// <summary>
    /// Biceps exercise.
    /// </summary>
    Biceps = "Biceps",

    /// <summary>
    /// Triceps exercise.
    /// </summary>
    Triceps = "Triceps",

    /// <summary>
    /// Shoulders exercise.
    /// </summary>
    Shoulders = "Shoulders",

    /// <summary>
    /// Legs exercise.
    /// </summary>
    Legs = "Legs",

    /// <summary>
    /// Forearms exercise.
    /// </summary>
    Forearms = "Forearms",

    /// <summary>
    /// Abs exercise.
    /// </summary>
    Abs = "Abs",

    /// <summary>
    /// All Other exercises.
    /// </summary>
    Other = "Other",
}

export { ExerciseType };
