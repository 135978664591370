import { useAuth0 } from "@auth0/auth0-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { AzureFunctionUri, checkIfAdmin, sortSetsInWorkout, useQuery, youtube_parser } from "./Constants";
import WorkoutModel, { SetModel, WorkoutExerciseModel, WorkoutStatus, WorkoutType } from "./models/WorkoutModel";
import YoutubeLogo from "./images/yt_icon.png";
import DuplicateWorkoutModal from "./DuplicateWorkoutModal";

function ViewWorkout() {
    const [loading, setLoading] = useState(true);
    const [isAdmin, setIsAdmin] = useState(false);
    const [userId, setUserId] = useState("");
    const [id, setId] = useState("");
    const [workout, setWorkout] = useState({} as WorkoutModel);
    const [showYoutubeModal, setShowYoutubeModal] = useState(false);
    const [youtubeModalLink, setYoutubeModalLink] = useState("");
    const [showDuplicateWorkoutModal, setShowDuplicateWorkoutModal] = useState(false);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const isMountedRef = useRef(true);
    const query = useQuery();
    const history = useHistory();

    const fetchWorkout = useCallback(async () => {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRef.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(AzureFunctionUri + "api/Workout/" + encodeURIComponent(userId) + "/" + id, {
            cache: "no-cache",
            headers: {
                Authorization: "Bearer " + accessToken,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error("Network response was not ok. ViewWorkout (GET: Workout): " + response.statusText + "(" + response.status + ")");
                }
                return response.json();
            })
            .then((data) => {
                var newWorkout: WorkoutModel = JSON.parse(data);
                if (isMountedRef.current) {
                    sortSetsInWorkout(newWorkout);
                    setWorkout(newWorkout);
                    setLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRef.current) setLoading(false);
                history.push("/?errorMessage=Unable+to+get+workout.");
            });
    }, [id, getAccessTokenSilently, history, isAuthenticated, isLoading, userId]);

    function openYoutubeModal(videoLink: string) {
        var link = "https://www.youtube.com/embed/" + youtube_parser(videoLink);
        setYoutubeModalLink(link);
        setShowYoutubeModal(true);
    }

    useEffect(() => {
        if (!userId) {
            if (query.has("userId")) {
                setUserId(query.get("userId") ?? "");
            } else {
                history.push("/?errorMessage=An+error+has+occurred.");
            }
        }
        if (!id) {
            if (query.has("id")) {
                setId(query.get("id") ?? "");
            } else {
                history.push("/?errorMessage=An+error+has+occurred.");
            }
        }
    }, [history, query, userId, id]);

    useEffect(() => {
        if (userId && id) {
            fetchWorkout();
        }

        return () => {
            isMountedRef.current = false;
        };
    }, [id, fetchWorkout, userId]);

    useEffect(() => {
        async function check() {
            if (!isLoading && isAuthenticated) {
                setIsAdmin(await checkIfAdmin(getAccessTokenSilently));
            } else {
                setIsAdmin(false);
            }
        }
        check();
    }, [getAccessTokenSilently, isLoading, isAuthenticated]);

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </symbol>
                <symbol id="x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </symbol>
            </svg>
            <h1>View Workout</h1>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            {workout && (
                <div>
                    <div className="mb-3">
                        <label htmlFor="userIdInput" className="form-label">
                            User Id
                        </label>
                        <input
                            type="name"
                            className="form-control"
                            id="userIdInput"
                            placeholder="User Id"
                            name="user_id"
                            value={workout.user_id ?? ""}
                            readOnly
                        />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="dateInput" className="form-label">
                            Date
                        </label>
                        <input type="name" className="form-control" id="dateInput" placeholder="Date" name="date" value={workout.date ?? ""} readOnly />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="nameInput" className="form-label">
                            Workout Name
                        </label>
                        <input type="name" className="form-control" id="nameInput" placeholder="Name" name="name" value={workout.name ?? ""} readOnly />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="typeInput" className="form-label">
                            Workout Type
                        </label>
                        <select id="typeInput" className="form-select" aria-label="Select Workout Type" name="type" value={workout.type ?? ""} disabled>
                            <option value={WorkoutType.Main}>Main</option>
                            <option value={WorkoutType.WarmUp}>Warm Up</option>
                            <option value={WorkoutType.Finishers}>Finishers</option>
                            <option value={WorkoutType.CoolDown}>Cool Down</option>
                        </select>
                    </div>
                    <div className="mb-3">
                        Workout Status:
                        {workout.status === WorkoutStatus.NotStarted && (
                            <svg className="bi bi-x-circle align-middle ms-2" width="16" height="16" fill="red">
                                <use xlinkHref="#x-circle" />
                            </svg>
                        )}
                        {workout.status === WorkoutStatus.Started && (
                            <svg className="bi bi-check-circle ms-2" width="16" height="16" fill="#D4AC0D">
                                <use xlinkHref="#check-circle" />
                            </svg>
                        )}
                        {workout.status === WorkoutStatus.Completed && (
                            <svg className="bi bi-check-circle ms-2" width="16" height="16" fill="green">
                                <use xlinkHref="#check-circle" />
                            </svg>
                        )}
                    </div>
                    {workout.exercises &&
                        workout.exercises.map((workoutExerciseGroup: WorkoutExerciseModel[], workoutExerciseGroupIndex: number) => {
                            var borderColor = "purple";
                            var cardHeader = "Normal Exercise";
                            if (workoutExerciseGroup.length > 1) {
                                borderColor = "maroon";
                                cardHeader = "Superset Exercise";
                            }
                            return (
                                <Card key={workoutExerciseGroupIndex} border={borderColor} className="mb-2 border-3">
                                    <Card.Header>{cardHeader}</Card.Header>
                                    <Card.Body>
                                        {workoutExerciseGroup.map((workoutExercise: WorkoutExerciseModel, workoutExerciseIndex: number) => {
                                            return (
                                                <div key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}`}>
                                                    {(workoutExercise.id || workoutExercise.is_custom) && (
                                                        <>
                                                            <div className="d-flex justify-content-between">
                                                                <Card.Title className="d-inline">{workoutExercise.name}</Card.Title>{" "}
                                                                {workoutExercise.video_link && (
                                                                    <div className="d-inline align-middle">
                                                                        <img
                                                                            src={YoutubeLogo}
                                                                            alt="Youtube Logo"
                                                                            height="35"
                                                                            onClick={() => {
                                                                                openYoutubeModal(workoutExercise.video_link);
                                                                            }}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                            <Card.Text as="div">
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        className="form-label"
                                                                    >
                                                                        General Description
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id={`generalDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        placeholder="General Description"
                                                                        name="general_description"
                                                                        value={workoutExercise.general_description ?? ""}
                                                                        rows={3}
                                                                        readOnly
                                                                    ></textarea>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        className="form-label"
                                                                    >
                                                                        Custom Description
                                                                    </label>
                                                                    <textarea
                                                                        className="form-control"
                                                                        id={`customDescInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        placeholder="Custom Description"
                                                                        name="custom_description"
                                                                        value={workoutExercise.custom_description ?? ""}
                                                                        rows={3}
                                                                        readOnly
                                                                    ></textarea>
                                                                </div>
                                                                <div className="mb-3">
                                                                    <label
                                                                        htmlFor={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        className="form-label"
                                                                    >
                                                                        Minimum Sets
                                                                    </label>
                                                                    <input
                                                                        type="number"
                                                                        className="form-control"
                                                                        id={`minSetsInput${workoutExerciseGroupIndex}${workoutExerciseIndex}`}
                                                                        placeholder="1"
                                                                        name="min_sets"
                                                                        min={0}
                                                                        value={workoutExercise.min_sets ?? ""}
                                                                        readOnly
                                                                    />
                                                                </div>
                                                                {workoutExercise.sets &&
                                                                    workoutExercise.sets.map((set: SetModel, setIndex: number) => {
                                                                        return (
                                                                            <div key={`${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}>
                                                                                <div className="d-flex align-items-center mb-3">
                                                                                    <div className="col-2">Set {set.order + 1}:</div>
                                                                                    <div className="col-4">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control text-center"
                                                                                            id={`repsFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                            placeholder="Reps"
                                                                                            value={set.reps ?? 0}
                                                                                            min={0}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-1 text-center">X</div>
                                                                                    <div className="col-4">
                                                                                        <input
                                                                                            type="number"
                                                                                            className="form-control text-center"
                                                                                            id={`weightFloatingInput_${workoutExerciseGroupIndex}${workoutExerciseIndex}${setIndex}`}
                                                                                            placeholder="Weight"
                                                                                            value={set.weight ?? 0}
                                                                                            min={0}
                                                                                            readOnly
                                                                                        />
                                                                                    </div>
                                                                                    <div className="col-1 text-center">kg</div>
                                                                                </div>
                                                                            </div>
                                                                        );
                                                                    })}
                                                            </Card.Text>
                                                        </>
                                                    )}
                                                    {workoutExerciseIndex < workoutExerciseGroup.length - 1 && <hr />}
                                                </div>
                                            );
                                        })}
                                    </Card.Body>
                                </Card>
                            );
                        })}
                </div>
            )}
            <div className="d-flex justify-content-between">
                <button
                    className="btn btn-maroon mt-2"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    Back
                </button>
                {isAdmin && (
                    <button
                        className="btn btn-purple mt-2"
                        onClick={() => {
                            setShowDuplicateWorkoutModal(true);
                        }}
                    >
                        Duplicate
                    </button>
                )}
            </div>
            {showDuplicateWorkoutModal && (
                <DuplicateWorkoutModal
                    workout={workout}
                    closeModal={() => {
                        setShowDuplicateWorkoutModal(false);
                    }}
                />
            )}
            <Modal
                size="xl"
                show={showYoutubeModal}
                centered={true}
                onHide={() => {
                    setShowYoutubeModal(false);
                    setYoutubeModalLink("");
                }}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Youtube Video</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            position: "relative",
                            width: "100%",
                            height: 0,
                            paddingBottom: "56.25%",
                        }}
                    >
                        {youtubeModalLink && (
                            <iframe
                                className="mt-1"
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                }}
                                src={youtubeModalLink}
                                title="YouTube video player"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                width="100%"
                            ></iframe>
                        )}
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

export default ViewWorkout;
