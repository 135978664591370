import { ReactComponent as LeftArrow } from "./images/arrow-left-circle.svg";
import { ReactComponent as RightArrow } from "./images/arrow-right-circle.svg";
import { ReactComponent as ArrowReset } from "./images/record-circle.svg";
import {
    addDays,
    AzureFunctionUri,
    dateToStr,
    getDateRangeString,
    getDayString,
    getMonday,
    parseDate,
} from "./Constants";
import { Link, useHistory } from "react-router-dom";
import WorkoutModel, {
    WorkoutStatus,
    WorkoutType,
} from "./models/WorkoutModel";
import { Card, Modal } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";

interface Props {
    loading: boolean;
    setLoading: Function;
    date: Date;
    setDate: Function;
    userWorkouts: { [key: string]: WorkoutModel[][] };
    userId: string;
    fetchWorkouts: Function;
    isMountedRefUserSchedule: React.MutableRefObject<boolean>;
}

function UserSchedule({
    loading,
    setLoading,
    date,
    setDate,
    userWorkouts,
    userId,
    fetchWorkouts,
    isMountedRefUserSchedule,
}: Props) {
    const [showDeleteWorkoutModal, setShowDeleteWorkoutModal] = useState(false);
    const [viewingWorkout, setViewingWorkout] = useState({} as WorkoutModel);

    const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
    const history = useHistory();

    async function deleteWorkout(userId: string, id: string) {
        if (isLoading || (!isLoading && !isAuthenticated)) return;
        isMountedRefUserSchedule.current = true;
        setLoading(true);

        const accessToken = await getAccessTokenSilently();

        fetch(
            AzureFunctionUri +
                "api/Workout/" +
                encodeURIComponent(userId) +
                "/" +
                id,
            {
                method: "DELETE",
                cache: "no-cache",
                headers: {
                    Authorization: "Bearer " + accessToken,
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    throw new Error(
                        "Network response was not ok. UserSchedule (DELETE: Workout): " +
                            response.statusText +
                            "(" +
                            response.status +
                            ")"
                    );
                }
                fetchWorkouts();
            })
            .catch((error) => {
                console.error(error);
                if (isMountedRefUserSchedule.current) setLoading(false);
            });
    }

    return (
        <div>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="check-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" />
                </symbol>
                <symbol id="x-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </symbol>
                <symbol id="trash-can" viewBox="0 0 16 16">
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                        fillRule="evenodd"
                        d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                </symbol>
                <symbol id="plus-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </symbol>
            </svg>
            <div className="row mb-1">
                <div className="col d-flex align-items-center">
                    <h5 className="m-0">{getDateRangeString(date)}</h5>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center mb-1">
                <div>
                    <Link
                        to={
                            "/Workout/Create?userId=" +
                            encodeURIComponent(userId)
                        }
                        className="btn btn-purple ms-1"
                    >
                        <svg
                            width="18"
                            height="18"
                            fill="currentColor"
                            className="bi bi-plus-circle align-text-bottom"
                        >
                            <use xlinkHref="#plus-circle" />
                        </svg>
                    </Link>
                </div>
                <div>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(addDays(date, -7));
                        }}
                    >
                        <LeftArrow className="m-1" width="30" height="30" />
                    </button>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(getMonday(new Date()));
                        }}
                    >
                        <ArrowReset className="m-1" width="30" height="30" />
                    </button>
                    <button
                        className="btn p-0"
                        onClick={() => {
                            setDate(addDays(date, 7));
                        }}
                    >
                        <RightArrow className="m-1" width="30" height="30" />
                    </button>
                </div>
            </div>
            <div>
                {!loading &&
                    userWorkouts &&
                    userWorkouts[dateToStr(getMonday(date))] &&
                    (userWorkouts[dateToStr(getMonday(date))].length > 0 ? (
                        userWorkouts[dateToStr(getMonday(date))].map(
                            (
                                workouts: WorkoutModel[],
                                workoutsIndex: number
                            ) => {
                                if (workouts.length > 0) {
                                    return (
                                        <Card
                                            key={workoutsIndex}
                                            border="purple"
                                            className="mb-2 border-3"
                                        >
                                            <Card.Header>
                                                {getDayString(
                                                    parseDate(workouts[0].date)
                                                ) +
                                                    ", " +
                                                    workouts[0].date}
                                            </Card.Header>
                                            <Card.Body>
                                                {workouts.map(
                                                    (
                                                        workout: WorkoutModel,
                                                        workoutIndex: number
                                                    ) => {
                                                        return (
                                                            <div
                                                                key={`${workoutsIndex}${workoutIndex}`}
                                                            >
                                                                <Card.Title>
                                                                    {
                                                                        workout.name
                                                                    }
                                                                </Card.Title>
                                                                <Card.Text as="div">
                                                                    <div>
                                                                        Type:{" "}
                                                                        {workout.type ===
                                                                            WorkoutType.Main &&
                                                                            "Main"}
                                                                        {workout.type ===
                                                                            WorkoutType.WarmUp &&
                                                                            "Warm Up"}
                                                                        {workout.type ===
                                                                            WorkoutType.Finishers &&
                                                                            "Finishers"}
                                                                        {workout.type ===
                                                                            WorkoutType.CoolDown &&
                                                                            "Cool Down"}
                                                                    </div>
                                                                    <div>
                                                                        Status:
                                                                        {workout.status ===
                                                                            WorkoutStatus.Completed && (
                                                                            <svg
                                                                                className="bi bi-check-circle ms-2"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="green"
                                                                            >
                                                                                <use xlinkHref="#check-circle" />
                                                                            </svg>
                                                                        )}
                                                                        {workout.status ===
                                                                            WorkoutStatus.Started && (
                                                                            <svg
                                                                                className="bi bi-check-circle ms-2"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="#D4AC0D"
                                                                            >
                                                                                <use xlinkHref="#check-circle" />
                                                                            </svg>
                                                                        )}
                                                                        {workout.status ===
                                                                            WorkoutStatus.NotStarted && (
                                                                            <svg
                                                                                className="bi bi-x-circle align-middle ms-2"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="red"
                                                                            >
                                                                                <use xlinkHref="#x-circle" />
                                                                            </svg>
                                                                        )}
                                                                    </div>
                                                                    <div className="mt-2">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-maroon"
                                                                            onClick={() => {
                                                                                var idStr =
                                                                                    workout.id
                                                                                        ? `&id=${workout.id}`
                                                                                        : "";
                                                                                history.push(
                                                                                    `/Workout/View?userId=${workout.user_id}${idStr}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            View
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-outline-danger float-end ms-2"
                                                                            onClick={() => {
                                                                                setViewingWorkout(
                                                                                    workout
                                                                                );
                                                                                setShowDeleteWorkoutModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            <svg
                                                                                className="bi bi-trash"
                                                                                width="16"
                                                                                height="16"
                                                                                fill="currentColor"
                                                                            >
                                                                                <use xlinkHref="#trash-can" />
                                                                            </svg>
                                                                        </button>
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-purple float-end"
                                                                            onClick={() => {
                                                                                var idStr =
                                                                                    workout.id
                                                                                        ? `&id=${workout.id}`
                                                                                        : "";
                                                                                history.push(
                                                                                    `/Workout/Create?userId=${encodeURIComponent(
                                                                                        userId
                                                                                    )}${idStr}`
                                                                                );
                                                                            }}
                                                                        >
                                                                            Edit
                                                                        </button>
                                                                    </div>
                                                                </Card.Text>
                                                                {workoutIndex <
                                                                    workouts.length -
                                                                        1 && (
                                                                    <hr />
                                                                )}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </Card.Body>
                                        </Card>
                                    );
                                } else {
                                    return <div></div>;
                                }
                            }
                        )
                    ) : (
                        <div className="d-flex justify-content-center mt-2">
                            <h3>No Records</h3>
                        </div>
                    ))}
            </div>
            {loading && (
                <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            )}
            <Modal
                centered={true}
                show={showDeleteWorkoutModal}
                onHide={() => setShowDeleteWorkoutModal(false)}
                aria-labelledby="deleteExerciseModal"
            >
                <Modal.Header className="py-2" closeButton>
                    <Modal.Title id="deleteWorkoutModalLabel">
                        Delete Workout
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-1">
                        Are you sure you want to delete this Workout? <br />
                        Name: {viewingWorkout.name} <br />
                        Date: {viewingWorkout.date} <br />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => setShowDeleteWorkoutModal(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className="btn btn-danger"
                        onClick={() => {
                            deleteWorkout(
                                viewingWorkout.user_id,
                                viewingWorkout.id
                            );
                            setShowDeleteWorkoutModal(false);
                        }}
                        disabled={
                            viewingWorkout.user_id && viewingWorkout.date
                                ? false
                                : true
                        }
                    >
                        Delete
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default UserSchedule;
