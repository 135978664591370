import "./App.scss";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ContentContainer from "./ContentContainer";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import Home from "./Home";
import Sidebar from "./Sidebar";
import ReloadNotification from "./ReloadNotification";
import Schedule from "./Schedule";
import Settings from "./Settings";
import Auth0ProviderWithHistory from "./Auth0Provider";
import AlertMessage from "./AlertMessage";
import { useState } from "react";
import Profile from "./Profile";
import ProtectedRoute from "./ProtectedRoute";
import Users from "./Users";
import Exercises from "./Exercises";
import EditExercise from "./EditExercise";
import CreateExercise from "./CreateExercise";
import User from "./User";
import CreateWorkout from "./CreateWorkout";
import ViewWorkout from "./ViewWorkout";
import StartWorkout from "./StartWorkout";

function App() {
    const [showSettings, setShowSettings] = useState(false);
    const [isContainerFluid, setContainerFluid] = useState(false);

    return (
        <BrowserRouter>
            <Auth0ProviderWithHistory>
                <Sidebar showSettings={setShowSettings} />

                <ContentContainer isContainerFluid={isContainerFluid}>
                    <ReloadNotification />
                    <AlertMessage />
                    <Switch>
                        <Route path="/Home">
                            <Home
                                setContainerFluid={(value: boolean) => {
                                    setContainerFluid(value);
                                }}
                            />
                        </Route>
                        <Route path="/Dashboard">
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Schedule">
                            <ProtectedRoute>
                                <Schedule />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Profile">
                            <ProtectedRoute>
                                <Profile />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/User">
                            <ProtectedRoute scopes={["admin"]}>
                                <User />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Users">
                            <ProtectedRoute scopes={["admin"]}>
                                <Users />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Workout/Create">
                            <ProtectedRoute scopes={["admin"]}>
                                <CreateWorkout />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Workout/View">
                            <ProtectedRoute>
                                <ViewWorkout />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Workout/Start">
                            <ProtectedRoute>
                                <StartWorkout />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Exercise/Create">
                            <ProtectedRoute scopes={["admin"]}>
                                <CreateExercise />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Exercise/Edit">
                            <ProtectedRoute scopes={["admin"]}>
                                <EditExercise />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/Exercises">
                            <ProtectedRoute scopes={["admin"]}>
                                <Exercises />
                            </ProtectedRoute>
                        </Route>
                        <Route path="/">
                            <Home
                                setContainerFluid={(value: boolean) => {
                                    setContainerFluid(value);
                                }}
                            />
                        </Route>
                    </Switch>
                </ContentContainer>

                <Settings show={showSettings} setShow={setShowSettings} />

                <Footer />
            </Auth0ProviderWithHistory>
        </BrowserRouter>
    );
}

export default App;
