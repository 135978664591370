import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Container, Navbar, Offcanvas } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import LoginButton, { LogoutButton, SignupButton } from "./AuthButtons";
import Logo from "./images/logo512.png";
import { ReactComponent as Dumbell } from "./images/dumbell.svg";
import { checkIfAdmin } from "./Constants";

interface Props {
    showSettings: Function;
}
function Sidebar({ showSettings }: Props) {
    const [show, setShow] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const { isLoading, isAuthenticated, user, getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        async function check() {
            if (!isLoading && isAuthenticated) {
                setIsAdmin(await checkIfAdmin(getAccessTokenSilently));
            } else {
                setIsAdmin(false);
            }
        }
        check();
    }, [getAccessTokenSilently, isLoading, isAuthenticated]);

    return (
        <header>
            <svg xmlns="http://www.w3.org/2000/svg" style={{ display: "none" }}>
                <symbol id="home" viewBox="0 0 16 16">
                    <path d="M8.354 1.146a.5.5 0 0 0-.708 0l-6 6A.5.5 0 0 0 1.5 7.5v7a.5.5 0 0 0 .5.5h4.5a.5.5 0 0 0 .5-.5v-4h2v4a.5.5 0 0 0 .5.5H14a.5.5 0 0 0 .5-.5v-7a.5.5 0 0 0-.146-.354L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293L8.354 1.146zM2.5 14V7.707l5.5-5.5 5.5 5.5V14H10v-4a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v4H2.5z" />
                </symbol>
                <symbol id="speedometer2" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5V6a.5.5 0 0 1-1 0V4.5A.5.5 0 0 1 8 4zM3.732 5.732a.5.5 0 0 1 .707 0l.915.914a.5.5 0 1 1-.708.708l-.914-.915a.5.5 0 0 1 0-.707zM2 10a.5.5 0 0 1 .5-.5h1.586a.5.5 0 0 1 0 1H2.5A.5.5 0 0 1 2 10zm9.5 0a.5.5 0 0 1 .5-.5h1.5a.5.5 0 0 1 0 1H12a.5.5 0 0 1-.5-.5zm.754-4.246a.389.389 0 0 0-.527-.02L7.547 9.31a.91.91 0 1 0 1.302 1.258l3.434-4.297a.389.389 0 0 0-.029-.518z" />
                    <path
                        fillRule="evenodd"
                        d="M0 10a8 8 0 1 1 15.547 2.661c-.442 1.253-1.845 1.602-2.932 1.25C11.309 13.488 9.475 13 8 13c-1.474 0-3.31.488-4.615.911-1.087.352-2.49.003-2.932-1.25A7.988 7.988 0 0 1 0 10zm8-7a7 7 0 0 0-6.603 9.329c.203.575.923.876 1.68.63C4.397 12.533 6.358 12 8 12s3.604.532 4.923.96c.757.245 1.477-.056 1.68-.631A7 7 0 0 0 8 3z"
                    />
                </symbol>
                <symbol id="calendar3" viewBox="0 0 16 16">
                    <path d="M14 0H2a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zM1 3.857C1 3.384 1.448 3 2 3h12c.552 0 1 .384 1 .857v10.286c0 .473-.448.857-1 .857H2c-.552 0-1-.384-1-.857V3.857z" />
                    <path d="M6.5 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm-9 3a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2zm3 0a1 1 0 1 0 0-2 1 1 0 0 0 0 2z" />
                </symbol>
                <symbol id="gear-fill" viewBox="0 0 16 16">
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                </symbol>
                <symbol id="people" viewBox="0 0 16 16">
                    <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
                </symbol>
            </svg>
            <Navbar variant="light" bg="light" collapseOnSelect expand={false}>
                <Container fluid={true}>
                    <Navbar.Toggle onClick={handleShow} />
                    <Link to="/" className="navbar-brand mx-auto">
                        <img src={Logo} alt="J-Fit Logo" className="mx-2 align-middle" width="30" height="30" />
                        J-Fit
                    </Link>
                    <Offcanvas show={show} onHide={handleClose} className="bg-dark text-white border-end-0">
                        <Container>
                            <Offcanvas.Header closeButton closeVariant="white">
                                <Offcanvas.Title>
                                    <img src={Logo} alt="J-Fit Logo" className="mx-2 align-middle" width="30" height="30" />
                                    J-Fit
                                </Offcanvas.Title>
                            </Offcanvas.Header>
                            <hr className="m-0" />
                            <Offcanvas.Body>
                                <div className="d-flex flex-column">
                                    <ul className="nav nav-pills flex-column mb-auto">
                                        <li className="nav-item">
                                            <NavLink
                                                to="/Home"
                                                className="nav-link text-white"
                                                aria-current="page"
                                                onClick={handleClose}
                                                activeClassName="active"
                                                isActive={(match, location) => {
                                                    if (["/", "/Home"].includes(location.pathname)) {
                                                        return true;
                                                    }
                                                    return false;
                                                }}
                                            >
                                                <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                    <use xlinkHref="#home" />
                                                </svg>
                                                <span className="align-middle">Home</span>
                                            </NavLink>
                                        </li>
                                        {!isLoading && isAuthenticated && (
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/Dashboard" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                        <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                            <use xlinkHref="#speedometer2" />
                                                        </svg>
                                                        <span className="align-middle">Dashboard</span>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/Schedule" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                        <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                            <use xlinkHref="#calendar3" />
                                                        </svg>
                                                        <span className="align-middle">Schedule</span>
                                                    </NavLink>
                                                </li>
                                                {isAdmin && (
                                                    <>
                                                        <li className="nav-item">
                                                            <NavLink to="/Users" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                                <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                                    <use xlinkHref="#people" />
                                                                </svg>
                                                                <span className="align-middle">Users</span>
                                                            </NavLink>
                                                        </li>
                                                        <li className="nav-item">
                                                            <NavLink
                                                                to="/Exercises"
                                                                className="nav-link text-white"
                                                                onClick={handleClose}
                                                                activeClassName="active"
                                                            >
                                                                <Dumbell className="bi me-2" width="30" height="30" fill="currentColor" />
                                                                <span className="align-middle">Exercises</span>
                                                            </NavLink>
                                                        </li>
                                                    </>
                                                )}
                                            </>
                                        )}
                                        <hr />
                                        <li className="nav-item">
                                            <div className="d-grid">
                                                <button
                                                    className="nav-link text-white text-start"
                                                    onClick={() => {
                                                        handleClose();
                                                        showSettings(true);
                                                    }}
                                                >
                                                    <svg className="bi me-2" width="30" height="30" fill="currentColor">
                                                        <use xlinkHref="#gear-fill" />
                                                    </svg>
                                                    <span className="align-middle">Settings</span>
                                                </button>
                                            </div>
                                        </li>
                                        <hr />
                                        {!isLoading &&
                                            (isAuthenticated ? (
                                                <>
                                                    <li className="nav-item mb-2">
                                                        <NavLink to="/Profile" className="nav-link text-white" onClick={handleClose} activeClassName="active">
                                                            <div className="d-inline me-1">
                                                                <img
                                                                    src={user?.picture}
                                                                    className="rounded-circle border"
                                                                    alt="Profile Pic"
                                                                    width="35"
                                                                    height="35"
                                                                />
                                                            </div>
                                                            <div className="d-inline ms-1 align-middle">{user?.name}</div>
                                                        </NavLink>
                                                    </li>
                                                    <div className="row">
                                                        <div className="d-grid col">
                                                            <LogoutButton className="btn btn-danger" />
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="nav-item">
                                                        <div className="row">
                                                            <div className="d-grid col">
                                                                <LoginButton className="btn btn-purple" />
                                                            </div>
                                                            <div className="d-grid col">
                                                                <SignupButton className="btn btn-purple" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ))}
                                    </ul>
                                </div>
                            </Offcanvas.Body>
                        </Container>
                    </Offcanvas>
                </Container>
            </Navbar>
        </header>
    );
}

export default Sidebar;
